import { FC, useEffect } from "react";
import { ReOwnersDropdownProps } from "./ReOwnersDropdown.types";
import { reOwnerApi } from "src/api";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { AMPAutocomplete } from "src/components/common/AMPAutocomplete";
import { ReOwnerDTO } from "src/types/reOwner";
import { AutocompleteValue } from "src/types";
import { FormikProps, useFormikContext } from "formik";
import { ReOwnersFormFieldsProps } from "../ReOwnersForm.types";
import { useIntl } from "react-intl";
import { getHelperText } from "src/utils/forms";
import { AMPAutocompleteProps } from "src/components/common/AMPAutocomplete/AMPAutocomplete.types";
import { useAppSelector } from "src/app/hooks";
import { RoleEnum } from "src/enums";

export const ReOwnersDropdown: FC<ReOwnersDropdownProps> = ({
  setReOwner,
  business,
}) => {
  const intl = useIntl();

  const { authInfo } = useAppSelector((state) => state.common);
  const businessId = authInfo?.business?.id;
  const isSuperAdmin = authInfo?.roles[0].role === RoleEnum.SUPER_ADMIN;

  const { setFieldValue, handleBlur, touched, errors, values } =
    useFormikContext<ReOwnersFormFieldsProps>();

  const [reOwnersTrigger, reOwnersResponse] =
    reOwnerApi.useLazyGetReOwnersQuery();

  const callHandler = async () => {
    try {
      const params = {
        size: 1000,
        page: 0,
      };
      const data = isSuperAdmin ? {} : { businessId: businessId };

      const response = await reOwnersTrigger({
        params,
        data,
      }).unwrap();

      if (setReOwner) {
        setReOwner(response.content[0].id);
      }

      if (typeof values.realEstateId === "number") {
        const preselectedValue =
          response.content.find((item) => item.id === values.realEstateId)
            ?.id ?? response.content[0].id;

        setFieldValue("realEstateId", preselectedValue);
      } else {
        setFieldValue("realEstateId", null);
      }
    } catch (error) {
      if (isErrorObject(error)) {
        NotifyError(error.data.message);
      }
    }
  };

  const processedReOwners = (
    reOwners: ReOwnerDTO[] | undefined
  ): Array<AutocompleteValue> => {
    if (!reOwners) {
      return [
        {
          id: 0,
          label: "",
        },
      ];
    }

    return [
      { id: null as unknown as number, label: "" },
      ...reOwners.map((item) => ({
        id: item?.id,
        label: item?.name,
      })),
    ];
  };

  const handleChangeReOwnerValue = (
    setFieldValue: FormikProps<ReOwnersFormFieldsProps>["setFieldValue"],
    fieldName: string
  ): AMPAutocompleteProps["onChange"] => {
    return (_e, newValue) => {
      if (newValue) {
        setFieldValue(fieldName, newValue.id);
        if (setReOwner) {
          setReOwner(newValue.id);
        }
      }
    };
  };

  useEffect(() => {
    callHandler();
  }, [business]);

  return (
    <AMPAutocomplete
      options={processedReOwners(reOwnersResponse.data?.content)}
      value={processedReOwners(reOwnersResponse.data?.content).find(
        (item) => item.id === values.realEstateId
      )}
      label={"reOwner"}
      input={"realEstateId"}
      showAvatar
      onChange={handleChangeReOwnerValue(setFieldValue, "realEstateId")}
      onBlur={handleBlur}
      hasError={
        (touched?.["realEstateId"] && Boolean(errors["realEstateId"])) ||
        (touched?.["realEstateId"] && values["realEstateId"] === 0)
      }
      helperText={getHelperText("realEstateId", touched, errors, intl)}
    />
  );
};
