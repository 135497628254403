import * as Yup from "yup";

export interface ReOwnerFormProps {
  businessId: number;
  name: string;
}

export interface ReManagerFormType {
  businessId: number;
  emailAddress: string;
  firstName: string;
  lastName: string;
  language: string;
  realEstateId: number;
  role: string;
}

export const reOwnersFormSchema = Yup.object().shape({
  businessId: Yup.number().required().notOneOf([0]),
  name: Yup.string().max(255).required("schemaRequiredName"),
});

export const AddReManagerInitialValue: ReManagerFormType = {
  businessId: 0,
  realEstateId: 0,
  emailAddress: "",
  firstName: "",
  lastName: "",
  language: "",
  role: "BUSINESS",
};

export const reManagerFormSchema = Yup.object().shape({
  businessId: Yup.number().required().notOneOf([0]),
  realEstateId: Yup.number().required().notOneOf([0]),
  emailAddress: Yup.string()
    .email("schemaValidEmail")
    .max(255)
    .required("schemaRequiredEmail"),
  firstName: Yup.string().max(255).required("schemaRequiredFirstName"),
  lastName: Yup.string().max(255).required("schemaRequiredLastName"),
  language: Yup.string().required("schemaLanguageRequired"),
});
