// get current env
import { AutocompleteValue, ErrorObject, ItemCost } from "../types";
import theme from "../theme";
import { Dispatch } from "react";
import { IntlShape } from "react-intl";
import { LanguageEnum, NormalizeLanguageEnum } from "src/enums";

export const isDevelopmentMode = () => process.env.NODE_ENV === 'development';

// get current BASE_URL
export const getBaseURL = () => (
  process.env.REACT_APP_BACKEND_URL
);

// get current WS_BASE_URL
export const getWSBaseURL = () => (
  process.env.REACT_APP_WS_URL
);

export const getMapBaseAPI = () => process.env.REACT_APP_GOOGLE_API_KEY

export const convertTime = (value: number) => {
  if(!value){
    return 0
  } 
  const minutes = value % 60;
  const hours = Math.floor(value / 60);

  return `${formatTime(hours)}h:${formatTime(minutes)}m`;
}

export const formatTime = (time: number | string) => {
  return String(time).length === 1 ? `0${time}` : time
}

export enum AppRouteEnum {
  app = 'app',
  locations = 'locations',
  chargers = 'chargers',
  business = 'business',
  users = 'users',
  contracts = 'contracts',
  tariffs = 'tariffs',
  invoices = 'invoices',
  signUp = 'signUp',
  login = 'login',
  welcome = 'welcome',
  forgotPassword = 'forgot',
  completeRegistration = 'completeRegistration',
  confirmRegistration = 'confirmRegistration',
  resetPassword = 'resetPassword',
  verifyEmail = 'verifyEmail',
  configurations = 'configurations',
  reports = 'reports',
  termsOfUse = 'terms-of-use',
  termsOfUseEn = 'terms-of-use/en',
  termsOfUseFr = 'terms-of-use/fr',
  termsOfUseDe = 'terms-of-use/de',
  privacyPolicy = 'privacy-policy',
  privacyPolicyEn = 'privacy-policy/en',
  privacyPolicyFr = 'privacy-policy/fr',
  privacyPolicyDe = 'privacy-policy/de',
  support = 'support',
  supportEn = 'support/en',
  supportFr = 'support/fr',
  supportDe = 'support/de',
  notifications = 'notifications',
  technicalTeam = 'technical-team',
  reOwner = 're-owners',
}

export const AppRouteSpace = (parts: (AppRouteEnum | string)[], absolute = true ) => {
  const path = parts.join('/');
  return absolute? `/${path}`: path
}

export const getObjectFieldAsString = <T = unknown>(obj: T, field: string) => {
  if(field in obj) {
    return "" + obj[field as keyof T];
  }
  return undefined;
}

export const convertMinutes = (num: number) => {
  const hours = (num / 60);
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  const seconds = (minutes - rminutes) * 60
  const rseconds = Math.round(seconds)
  return `${rhours}h ${rminutes}m ${rseconds}s`
}

export const findAutocompleteValueById = (options: AutocompleteValue[], value?: number) => {
  if(!value){
    return undefined
  }
  return options.find(option => option.id == value)
}

export const findAutocompleteValueByLabel = (options: AutocompleteValue[], value?: string) => {
  if(!value){
    return undefined
  }
  return options.find(option => option.label === value)
}

export const isErrorObject = (input: unknown): input is ErrorObject => {
  return input instanceof Object && Object.keys(input).includes('data')
}
export const getNumberFromPx = (num: number) => +theme.spacing(num/8).replace('px', '')

export const formatFileName = (name: string) => name.replace(/^(\d+)(-)(.+)$/, '$3').replace(/_/g, ' ')

export const valuesTrimmer = <T = unknown>(obj: T) => {
  const objMap = new Map<string, unknown>();
  for (const key in obj) {
    const item = obj[key];
    if (typeof item === 'string') {
      objMap.set(key, item.trim());
      continue;
    }
  }
  return {
    ...obj,
    ...Object.fromEntries(objMap)
  } as T
}

export const destinctSliceCollection = <T extends { chargerId: number }>(collection: T[], payload: T) => {
  return collection.length ? collection.filter(item => item.chargerId !== payload.chargerId).concat([payload]) : [payload];
}

export const handleScroll = (currentPage: number, totalPages: number, page: number, setPage: Dispatch<number>) => {
  if (currentPage < totalPages - 1) {
    setPage(page + 1)
  }
}

export const generateItemCost = (costs: ItemCost[]) => {
  const result = costs.reduce((sum, cost) =>
    sum + cost.currentCost, 0)
  const currency = costs[0]?.currency ?? ''
  return `${result} ${currency}`
}

export const processedLanguages = (intl: IntlShape) => ([
  {
    id: 0,
    name: intl.formatMessage({id: NormalizeLanguageEnum.ENGLISH}),
    value: LanguageEnum.ENGLISH,
  },
  {
    id: 1,
    name: intl.formatMessage({id: NormalizeLanguageEnum.FRENCH}),
    value: LanguageEnum.FRENCH,
  },
  {
    id: 2,
    name: intl.formatMessage({id: "german"}),
    value: LanguageEnum.DEUTSCH,
  },
]
)

