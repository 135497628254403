import { IntlShape } from "react-intl";
import dayjs from "dayjs";
import {
  ContractStatusManualEnum,
  CurrencyEnum,
  LanguageEnum,
  TitleEnum,
} from "../../../../enums";
import { BlockStatusEnum } from "../../../common/RfidTable/RfidTable.types";
import { TimeMapType } from "../../../reports/ReportCard/ReportCard.types";
import { UserFormType } from "../user";

export const loginInitialValues = {
  emailAddress: "",
  password: "",
};

export const initContractGeneral = {
  contractCreatedAt: dayjs(dayjs().format("YYYY-MM-DD")).valueOf(),
  operationalAt: dayjs(dayjs().format("YYYY-MM-DD")).valueOf(),
  contractId: "",
  contractStatus: ContractStatusManualEnum.INSTALLATION,
  lastInvoicedWattsConsumed: 0,
  totalTariffPeriodWattsConsumed: 0,
  lastAmount: 0,
  userId: "",
};

export enum TimeMapEnum {
  LAST_WEEK = 1,
  LAST_MONTH = 2,
  THIS_YEAR = 3,
  ALL = 4,
  CUSTOM = 5,
  CONTRACT = 6,
}

export const timeMap = (
  intl: IntlShape,
  handleCustomClick: () => void
): TimeMapType => ({
  [TimeMapEnum.LAST_WEEK]: {
    title: intl.formatMessage({ id: "lastWeek" }),
    dateFrom: dayjs().subtract(1, "week").valueOf(),
  },
  [TimeMapEnum.LAST_MONTH]: {
    title: intl.formatMessage({ id: "last30days" }),
    dateFrom: dayjs().subtract(1, "month").valueOf(),
  },
  [TimeMapEnum.THIS_YEAR]: {
    title: intl.formatMessage({ id: "thisYear" }),
    dateFrom: dayjs().startOf("year").valueOf(),
  },
  [TimeMapEnum.ALL]: {
    title: intl.formatMessage({ id: "all" }),
    dateFrom: 0,
  },
  [TimeMapEnum.CUSTOM]: {
    title: intl.formatMessage({ id: "custom" }),
    dateFrom: 0,
    onClick: handleCustomClick,
  },
});

export const signUpInitialValues = {
  city: "",
  country: "",
  region: "",
  houseNumber: "",
  street: "",
  zipCode: "",
  businessId: 1,
  emailAddress: "",
  password: "",
  confirmPassword: "",
  parkingLot: "",
  firstName: "",
  lastName: "",
  language: LanguageEnum.ENGLISH,
  agreements: false,
};

export const feedbackInitialValues = {
  // phone: '',
  message: "",
};

export const locationInitialValues = {
  name: "",
  country: "",
  region: "",
  city: "",
  zipCode: "",
  street: "",
  houseNumber: "",
  parkingLots: 0,
  availableAmperes: 0,
};

export const createContractManuallySteps = (intl: IntlShape) => [
  {
    title: intl.formatMessage({ id: "generalInfo" }),
    description: intl.formatMessage({ id: "clientContractInfo" }),
  },
  {
    title: intl.formatMessage({ id: "tariffs" }),
    description: intl.formatMessage({ id: "chooseTariff" }),
  },
  {
    title: intl.formatMessage({ id: "contract" }),
    description: intl.formatMessage({ id: "uploadDocument" }),
  },
];

export const rfidStatuses = (intl: IntlShape) => [
  {
    id: 0,
    name: intl.formatMessage({ id: BlockStatusEnum.blocked }),
    value: BlockStatusEnum.blocked,
  },
  {
    id: 1,
    name: intl.formatMessage({ id: BlockStatusEnum.accepted }),
    value: BlockStatusEnum.accepted,
  },
];

export const currencies = (intl: IntlShape) => [
  {
    id: 0,
    name: intl.formatMessage({ id: "EUR" }),
    value: CurrencyEnum.EUR,
  },
  {
    id: 1,
    name: intl.formatMessage({ id: "CHF" }),
    value: CurrencyEnum.CHF,
  },
];

export const titles = (intl: IntlShape) => [
  {
    id: 0,
    name: intl.formatMessage({ id: "miss" }),
    value: TitleEnum.MISS,
  },
  {
    id: 1,
    name: intl.formatMessage({ id: "mr" }),
    value: TitleEnum.MR,
  },
  {
    id: 2,
    name: intl.formatMessage({ id: "mrs" }),
    value: TitleEnum.MRS,
  },
  {
    id: 4,
    name: intl.formatMessage({ id: "other" }),
    value: TitleEnum.OTHER,
  },
];

export const filterInitialValues = {
  countries: "",
  city: "",
};

export const AddUserInitialValue: UserFormType = {
  bexioId: 0,
  cityParking: "",
  countryParking: "",
  regionParking: "",
  houseNumberParking: "",
  streetParking: "",
  zipCodeParking: "",
  parkingLotParking: "",
  cityHome: "",
  countryHome: "",
  regionHome: "",
  houseNumberHome: "",
  streetHome: "",
  zipCodeHome: "",
  businessId: 0,
  emailAddress: "",
  firstName: "",
  lastName: "",
  language: "",
  phoneNumber: "",
  title: "",
  useParkingAddress: false,
};

export const chargerInitialValues = {
  chargePointId: "",
  locationId: "",
  modelId: "",
  userId: "",
};

export const tariffInitialValues = {
  name: "",
  country: "",
  region: "",
  nameFrench: "",
  nameDeutsch: "",
  description: "",
  descriptionFrench: "",
  descriptionDeutsch: "",
  currency: "EUR",
  annualWhLimit: 10,
  pricePerKWh: 0,
  monthlyFee: 0,
  contractPeriodLimit: 0,
  businessId: 0,
};

export const forgotPasswordFormInitialValues = {
  emailAddress: "",
};

export const resetPasswordFormInitialValues = {
  password: "",
  confirmPassword: "",
};

export const addClientToLocationFormValues = {
  userId: "",
  parkingPlace: "",
};

export const contractInitialValues = {
  language: "ENGLISH",
  phoneNumber: "",
  city: "",
  country: "",
  region: "",
  houseNumber: "",
  street: "",
  zipCode: "",
  useParkingAddress: true,
};

export const tariffDescriptionValues = {
  description: "",
  descriptionFrench: "",
};

export const AddBusinessInitialValue = {
  city: "",
  country: "",
  region: "",
  houseNumber: "",
  street: "",
  zipCode: "",
  emailAddress: "",
  name: "",
  language: "",
  shareClients: false,
  userPerMonthCost: 0,
  userPerMonthCurrency: CurrencyEnum.EUR,
};

export const scheduleInitialValue = {
  startDay: "",
  startHour: "",
  startMinute: "",
  stopDay: "",
  stopHour: "",
  stopMinute: "",
  type: false,
};

export const managersInitialValue = {
  firstName: "",
  lastName: "",
  language: "",
  role: "",
  email: "",
};

export const reOwnersInitialValue = {
  businessId: 0,
  name: "",
};
