import { SxProps, Theme } from "@mui/material";
import theme from "src/theme";

export const formSx: SxProps<Theme> = {
  display: "grid",
  gridTemplateColumns: "auto",
  gap: theme.spacing(20 / 8),
  padding: theme.spacing(20 / 8),
};

export const actionsWrapperSx: SxProps<Theme> = {
  padding: theme.spacing(20 / 8, 24 / 8),
  borderTop: `${theme.spacing(1 / 8)} solid ${theme.palette.neutral[200]}`,
  background: theme.palette.neutral[50],
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: theme.spacing(20 / 8),
};
