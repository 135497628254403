import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { usersApi } from "src/api";
import AMPModal from "src/components/common/AMPModal";
import {
  actionsWrapperSx,
  addReManagerButtonSx,
  formSx,
} from "./AddReManager.sx";
import { useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { useAppSelector } from "src/app/hooks";
import { RoleEnum } from "src/enums";
import { AMPFormSelect } from "src/components/common/AMPFormSelect";
import { getHelperText } from "src/utils/forms";
import { AMPInput } from "src/components/common/AMPInput";
import { CancelButton } from "src/components/common/CancelButton";
import { SubmitButton } from "src/components/common/SubmitButton";
import { isErrorObject, processedLanguages } from "src/utils/helper";
import {
  AddReManagerInitialValue,
  reManagerFormSchema,
  ReManagerFormType,
} from "../formData/reOwners";
import { BusinessesDropdown } from "../ReOwnersForm/BusinessesDropdown";
import { ReOwnersDropdown } from "../ReOwnersForm/ReOwnersDropdown";
import { NotifyError } from "src/utils/notification";
import { useParams } from "react-router-dom";

export const AddReManager = () => {
  const intl = useIntl();

  const theme = useTheme();

  const { id: reOwnerId } = useParams();

  const { authInfo } = useAppSelector((state) => state.common);

  const isSuperAdmin = authInfo?.roles?.[0]?.role === RoleEnum.SUPER_ADMIN;
  const defaultBusinessId = authInfo?.business?.id ?? 0;
  const isBusinessOwner = authInfo?.roles[0].role === RoleEnum.BUSINESS;

  const dialogTitle = intl.formatMessage({ id: "addReManager" });

  const [open, setOpen] = useState<boolean>(false);

  const [createManagerTrigger] = usersApi.useCreateManagerMutation();

  const handleSubmit = async (values: ReManagerFormType) => {
    const {
      emailAddress,
      firstName,
      lastName,
      realEstateId,
      businessId,
      language,
    } = values;

    try {
      await createManagerTrigger({
        data: {
          email: emailAddress,
          firstName,
          lastName,
          realEstateId,
          role: RoleEnum.REAL_ESTATE,
          language,
        },
        businessId,
      }).unwrap();
    } catch (error) {
      if (isErrorObject(error)) {
        NotifyError(error.data.message);
      }
    } finally {
      handleCloseModal();
    }
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        sx={addReManagerButtonSx(theme)}
        color="brand"
        variant="contained"
        onClick={handleOpenModal}
      >
        <Typography 
          variant="button"
          color="textSecondary"
        >
          <FormattedMessage id={"addReManager"} />
        </Typography>
      </Button>

      <AMPModal
        handleClose={handleCloseModal}
        open={open}
        title={dialogTitle}
        type="md"
        fullWidth
        withoutPaddings
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Formik
            initialValues={
              isSuperAdmin
                ? AddReManagerInitialValue
                : {
                  ...AddReManagerInitialValue,
                  businessId: defaultBusinessId,
                  realEstateId: reOwnerId ? parseInt(reOwnerId) : 0,
                }
            }
            validationSchema={reManagerFormSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }: FormikProps<ReManagerFormType>) => (
              <Form
                onSubmit={handleSubmit}
                noValidate
              >
                <Box sx={formSx(theme)}>
                  {/* Business */}
                  {!isBusinessOwner && (
                    <Grid
                      lg={12}
                      item
                    >
                      <BusinessesDropdown />
                    </Grid>
                  )}

                  {/* RE Owner */}
                  <Grid
                    lg={12}
                    item
                  >
                    <ReOwnersDropdown business={values.businessId} />
                  </Grid>

                  {/* First Name */}
                  <Grid
                    lg={12}
                    item
                  >
                    <AMPInput
                      value={values["firstName"]}
                      type={"firstName"}
                      label={"firstName"}
                      input={"firstName"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={
                        touched["firstName"] && Boolean(errors["firstName"])
                      }
                      helperText={getHelperText(
                        "firstName",
                        touched,
                        errors,
                        intl
                      )}
                    />
                  </Grid>

                  {/* Last Name */}
                  <Grid
                    lg={12}
                    item
                  >
                    <AMPInput
                      value={values["lastName"]}
                      type={"lastName"}
                      label={"lastName"}
                      input={"lastName"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={
                        touched["lastName"] && Boolean(errors["lastName"])
                      }
                      helperText={getHelperText(
                        "lastName",
                        touched,
                        errors,
                        intl
                      )}
                    />
                  </Grid>

                  {/* Email address */}
                  <Grid 
                    lg={12}
                    item
                  >
                    <AMPInput
                      value={values["emailAddress"]}
                      type={"emailAddress"}
                      label={"emailAddress"}
                      input={"emailAddress"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      hasError={
                        touched["emailAddress"] &&
                        Boolean(errors["emailAddress"])
                      }
                      helperText={getHelperText(
                        "emailAddress",
                        touched,
                        errors,
                        intl
                      )}
                    />
                  </Grid>

                  {/* Language */}
                  <AMPFormSelect
                    data={processedLanguages(intl)}
                    value={values["language"] as string}
                    label={"language"}
                    input={"language"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["language"] && Boolean(errors["language"])
                    }
                    helperText={getHelperText(
                      "language",
                      touched,
                      errors,
                      intl
                    )}
                  />
                </Box>

                <Box sx={actionsWrapperSx(theme)}>
                  <CancelButton
                    text="cancel"
                    handler={handleCloseModal}
                  />
                  <SubmitButton
                    text={"createUser"}
                    color="primary"
                    isSubmitting={isSubmitting}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </AMPModal>
    </>
  );
};
