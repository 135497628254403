import { FormikConfig } from "formik";
import { FC, useState } from "react";
import { locationsApi } from "src/api";
import { useAppDispatch } from "src/app/hooks";
import { LocationType } from "src/components/forms/formData/location";
import { LocationForm } from "src/components/forms/LocationForm";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch, LatLngType, LocationFileDTO } from "src/types";
import { LocationUpdateFormWrapperType } from "./LocationUpdateFormWrapper.types";
import { isErrorObject, valuesTrimmer } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { allRegionsValue } from "../../../utils/consts";

type LocationUpdateMutationTrigger = ReturnType<typeof locationsApi.useUpdateLocationMutation>[0]
type SetLocationFileMutationTrigger = ReturnType<typeof locationsApi.useAddFileToLocationMutation>[0]

const handleSubmit = (
  trigger: LocationUpdateMutationTrigger,
  dispatch: AppDispatch,
  setLocationFile: SetLocationFileMutationTrigger,
  businessId: number,
  handleClose: () => void,
  locationId: number,
  mark: LatLngType,
  files?: File[] | null
): FormikConfig<LocationType>['onSubmit'] => (async (value) => {
  const values = valuesTrimmer(value)
  const data = {
    address: {
      region:  values.region === allRegionsValue ? undefined :  values.region,
      city: values.city,
      country: values.country,
      street: values.street,
      zipCode: values.zipCode,
      houseNumber: values.houseNumber,
    },
    businessId: businessId,
    name: values.name,
    latitude: mark.lat,
    longitude: mark.lng,
    availableAmperes: values.availableAmperes,
    parkingLots: values.parkingLots,
    realEstateId: values.realEstateId,
  }
  try {
    dispatch(preloaderChangeStatus(true))
    const response = trigger({locationId, data}).unwrap()
    let status: Promise<LocationFileDTO>[] = [];
    if(files) {
      status = files.map((file) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.set('businessId', String(businessId))
        return setLocationFile({
          locationId: locationId,
          formData
        }).unwrap()
      })
    }
    await Promise.allSettled([...status, response])
    handleClose()
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
  }
});

export const LocationUpdateFormWrapper: FC<LocationUpdateFormWrapperType> = ({ handleClose, initialValue, locationId }) => {

  const [trigger] = locationsApi.useUpdateLocationMutation()
  const [setLocationFile] = locationsApi.useAddFileToLocationMutation()
  const dispatch = useAppDispatch()
  const [file, setFile] = useState<File[] | null>(null)
  const [mark, setMark] = useState<LatLngType>({
    lat: 0,
    lng: 0
  })
  const handleAddFile = (draggedFile: File[] | null)=> {
    setFile(draggedFile)
  }

  const placeMark = (data: LatLngType) => {
    setMark(data)
  }

  return (
    <LocationForm
      handleClose={handleClose}
      initialValue={initialValue}
      handleSubmit={handleSubmit(trigger, dispatch, setLocationFile, initialValue.business.id, handleClose, +locationId, mark, file)}
      handleAddFile={handleAddFile}
      setMark={placeMark}
    />
  )
}
