import { SxProps, Theme } from "@mui/material";

export const wrapperSx = (theme: Theme): SxProps<Theme> => ({
  height: theme.spacing(600 / 8),
  width: "100%",
});

export const tableFooterSx = (_theme: Theme): SxProps<Theme> => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  px: 0,
  py: 1,
});
