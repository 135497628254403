import { FC } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { EmptyTableWrapper } from "src/components/tables/EmptyTableWrapper";
import { EmptyRfidIcon } from "../../../icons/EmptyRfidIcon";
import { boxSx, btnSx, textSx } from "./EmptyRfid.sx";
import { EmptyRfidProps } from "./EmptyRfid.types";
import { RoleEnum } from "../../../../enums";
import { useDefineIsRealEstateRole } from "src/app/hooks";

export const EmptyRfid: FC<EmptyRfidProps> = ({ handleClick, role }) => {
  const isRealEstate = useDefineIsRealEstateRole();

  return (
    <EmptyTableWrapper>
      <Stack sx={boxSx}>
        <EmptyRfidIcon />
        <Typography variant="body1" sx={textSx}>
          <FormattedMessage id="noRfid" />
        </Typography>
        {!isRealEstate && (
          <Button
            onClick={handleClick}
            variant="outlined"
            sx={btnSx}
            startIcon="+"
          >
            <FormattedMessage
              id={role === RoleEnum.DRIVER ? "requestRfid" : "addRfid"}
            />
          </Button>
        )}
      </Stack>
    </EmptyTableWrapper>
  );
};
