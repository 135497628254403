import { store } from "src/app/store";
import { axiosInstance } from ".";
import CryptoJs from 'crypto-js'
import { RoleEnum } from "src/enums";

export const cryptoKey = process.env.REACT_APP_PASS_PHRASE ?? '';

type ConfigType = Parameters<NonNullable<Parameters<typeof axiosInstance.interceptors.request.use>[0]>>[0]
type ErrorType = Parameters<NonNullable<Parameters<typeof axiosInstance.interceptors.request.use>[1]>>[0]


export const authHeadersFromLocalStorage = 
async (config: ConfigType) => {
  
  if (!localStorage.getItem('auth')) {
    return config
  }
  const auth = localStorage.getItem('auth') ?? ''
  const data = JSON.parse(CryptoJs.AES.decrypt(auth, cryptoKey.toString()).toString(CryptoJs.enc.Utf8));
  if(config.headers) {
    config.headers.authorization = data.authorization
  }

  const authInfo = store?.getState()?.common?.authInfo
  const role = authInfo?.roles?.[0].role
  const businessId = authInfo?.business?.id
  const realEstateId = authInfo?.realEstate?.id

  if (role === RoleEnum.REAL_ESTATE && businessId && realEstateId) {
    config["data"] = {
      ...config["data"],
      businessId, 
      realEstateId,
    }
  }

  return config
}

export const rejectError = (error: ErrorType) => Promise.reject(error)
