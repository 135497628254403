import { Box } from "@mui/material";
import { GridSortItem, GridSortModel } from "@mui/x-data-grid";
import { FC, useState } from "react";
import { TablePagination } from "src/components/tables/TablePagination";
import { TableRowCounter } from "src/components/tables/TableRowCounter";
import { Table } from "../../tables/Table";
import { tableFooterSx } from "./ChargerTable.sx";
import { ChargerTableProps } from "./ChargerTable.types";
import { columnConfigChargers } from "./ChargerTable.tableConfig";
import { ChargerFilterValue } from "../ChargerTableActions/ChargerTableActions.types";
import { ChargerSortFieldEnum } from "src/enums";
import { ChargerTableActions } from "../ChargerTableActions";
import { useAppSelector, useDefineIsRealEstateRole } from "src/app/hooks";
import { ChargerListCard } from "../ChargerListCard";
import { ResponsiveTable } from "../../common/ResponsiveTable";

export const ChargerTable: FC<ChargerTableProps> = ({
  data,
  pageNumber,
  pageSize,
  handleUpdateTableData,
  handleChangePage,
  handleChangeRowsNumber
}) => {
  const { authInfo } = useAppSelector(state => state.common)
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterValue, setFilterValue] = useState<ChargerFilterValue>({
    country: '',
    city: '',
    chargePointStatus: '',
    connectorStatus: '',
    businessId: 0
  });
  const [sortValue, setSortValue] = useState<GridSortItem>({ field: '', sort: 'asc' });

  const isRealEstate = useDefineIsRealEstateRole();

  const handleSearch = ( value: string ) => {
    setSearchValue(value)
    handleUpdateTableData(updateData(value, sortValue, filterValue))
  }

  const handleFilter = ( value: ChargerFilterValue ) => {
    setFilterValue(value)
    handleUpdateTableData(updateData(searchValue, sortValue, value))
  }

  const handleSort = ( value: GridSortModel ) => {
    setSortValue(value[0])
    handleUpdateTableData(updateData(searchValue, value[0], filterValue))
  }

  const updateData = (search: string, sort: GridSortItem, filter: ChargerFilterValue) => {
    return {
      businessId: filter.businessId,
      country: filter.country,
      city: filter.city,
      chargePointStatus: filter.chargePointStatus,
      connectorStatus: filter.connectorStatus,
      keyword: search,
      sortAsc: sort ? sort.sort === 'asc' : true,
      sortField: (sort && sort.field) || ChargerSortFieldEnum.BUSINESS_ID
    }
  }
  
  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <ChargerTableActions 
        handleSearch={handleSearch}
        handleFilter={handleFilter}
      />
      <ResponsiveTable
        responsiveElement={
          <Box>
            {data?.content?.map(item => (
              <ChargerListCard
                key={item?.id}
                {...item}
                canRemoveCharger={!isRealEstate}
              />
            ))}
          </Box>
        }
        desktopElement={
          <Table
            rows={data.content}
            hideFooter
            columns={columnConfigChargers(authInfo.roles[0].role, !isRealEstate)}
            handleSort={handleSort}
          />
        }
      />
      <Box sx={tableFooterSx}>
        <TableRowCounter
          defaultValue={pageSize}
          handleChangeRowsNumber={handleChangeRowsNumber}
        />
        <TablePagination
          totalPages={data.totalPages}
          page={pageNumber && pageNumber === 0 ? pageNumber : data.number}
          handleChangePage={handleChangePage}
        />
      </Box>
    </Box>
  )
}
