import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import commonReducer from '../stores/common/commonSlice';
import {
  authApi,
  registerApi,
  locationsApi,
  usersApi,
  chargersApi,
  tariffsApi,
  contractsApi,
  transactionsApi,
  invoicesApi,
  ocppApi,
  reportsApi,
  systemApi,
  businessesApi,
  notificationApi,
  reOwnerApi,
} from 'src/api';


const rootReducer = combineReducers({
  common: commonReducer,
  [authApi.reducerPath]: authApi.reducer,
  [registerApi.reducerPath]: registerApi.reducer,
  [locationsApi.reducerPath]: locationsApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [chargersApi.reducerPath]: chargersApi.reducer,
  [tariffsApi.reducerPath]: tariffsApi.reducer,
  [contractsApi.reducerPath]: contractsApi.reducer,
  [transactionsApi.reducerPath]: transactionsApi.reducer,
  [businessesApi.reducerPath]: businessesApi.reducer,
  [invoicesApi.reducerPath]: invoicesApi.reducer,
  [ocppApi.reducerPath]: ocppApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [systemApi.reducerPath]: systemApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [reOwnerApi.reducerPath]: reOwnerApi.reducer,
})

const persistConfig = {
  key: 'root',
  version: 1,
  storage: localStorage,
  whitelist: ['common'],
}

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
    .concat([
      authApi.middleware,
      registerApi.middleware,
      locationsApi.middleware,
      usersApi.middleware,
      chargersApi.middleware,
      tariffsApi.middleware,
      contractsApi.middleware,
      transactionsApi.middleware,
      businessesApi.middleware,
      invoicesApi.middleware,
      ocppApi.middleware,
      reportsApi.middleware,
      systemApi.middleware,
      notificationApi.middleware,
      reOwnerApi.middleware,
    ])
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
