import { FC, useEffect } from "react";
import { businessesApi } from "src/api";
import { useAppSelector } from "src/app/hooks";
import { AMPAutocomplete } from "src/components/common/AMPAutocomplete";
import { RoleEnum } from "src/enums";
import { BusinessDTO, AutocompleteValue } from "src/types";
import { getBusinessesData } from "../../UserForm";
import { FormikProps, useFormikContext } from "formik";
import { AMPAutocompleteProps } from "src/components/common/AMPAutocomplete/AMPAutocomplete.types";
import { getHelperText } from "src/utils/forms";
import { useIntl } from "react-intl";
import { BusinessesDropdownProps } from "./BusinessesDropdown.types";
import { ReOwnersFormFieldsProps } from "../ReOwnersForm.types";

export const BusinessesDropdown: FC<BusinessesDropdownProps> = ({
  setBusiness,
}) => {
  const { authInfo } = useAppSelector((state) => state.common);
  const businessId = authInfo?.business?.id;
  const businessName = authInfo?.business?.name;
  const roles = authInfo?.business?.roles;
  const isSuperAdmin = roles?.[0]?.role === RoleEnum.SUPER_ADMIN;

  const { setFieldValue, handleBlur, touched, errors, values } =
    useFormikContext<ReOwnersFormFieldsProps>();

  const intl = useIntl();

  const [getBusinesses, businesses] = businessesApi.useLazyGetBusinessesQuery();

  const processedBusinesses = (
    businesses: BusinessDTO[] | undefined,
    preselectedBusinessId: string,
    preselectedBusinessName: string
  ): AutocompleteValue[] => {
    if (businesses) {
      return businesses.map((item) => ({
        id: item?.id,
        label: item?.name,
        image: item?.logo?.url ?? "",
      }));
    }
    return [
      {
        id: Number(preselectedBusinessId) ?? 0,
        label: preselectedBusinessName ?? "",
      },
    ];
  };

  const calculateDefaultBusinessId = (
    businessId: string,
    isSuperAdmin: boolean,
    businesses: BusinessDTO[] | undefined,
    businessName: string
  ): AutocompleteValue | undefined => {
    const result = processedBusinesses(
      businesses,
      businessId,
      businessName
    ).find((item) => item.id === Number(values.businessId));

    return result;
  };

  const handleChangeBusinessValue = (
    setFieldValue: FormikProps<ReOwnersFormFieldsProps>["setFieldValue"],
    fieldName: string
  ): AMPAutocompleteProps["onChange"] => {
    return (_e, newValue) => {
      if (newValue) {
        setFieldValue(fieldName, newValue.id);
        if (setBusiness) {
          setBusiness(newValue.id);
        }
      }
    };
  };

  useEffect(() => {
    getBusinessesData(getBusinesses);
  }, []);

  const options = processedBusinesses(
    businesses?.data?.content,
    businessId,
    businessName
  );

  return (
    <AMPAutocomplete
      options={
        businessId
          ? [
              options.find(
                (item) => item.id === Number(businessId)
              ) as AutocompleteValue,
          ]
          : options
      }
      value={calculateDefaultBusinessId(
        businessId,
        isSuperAdmin,
        businesses.data?.content,
        businessName
      )}
      label={"business"}
      input={"businessId"}
      showAvatar
      onChange={handleChangeBusinessValue(setFieldValue, "businessId")}
      onBlur={handleBlur}
      hasError={
        (touched?.["businessId"] && Boolean(errors["businessId"])) ||
        (touched?.["businessId"] && values["businessId"] === 0)
      }
      helperText={getHelperText("businessId", touched, errors, intl)}
    />
  );
};
