import { Box, Grid } from "@mui/material";
import { FC, useEffect } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { locationsApi } from "src/api/locations";
import { useAppDispatch, useAppSelector, useDefineIsRealEstateRole } from "src/app/hooks";
import { AppDispatch, store } from "src/app/store";
import { PageHeader } from "src/components/common/PageHeader";
import { ClientListInLocationDetails } from "src/components/locations/ClientListInLocationDetails";
import { LocationDetails } from "src/components/locations/LocationDetails";
import { LocationFiles } from "src/components/locations/LocationFiles";
import ability from "src/permissions/ability";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { GetLocationByIdType } from "src/types/locations";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { LocationInfoProvider } from "./LocationInfo.context";

type LocationQueryTrigger = ReturnType<typeof locationsApi.useLazyGetLocationByIdQuery>[0];

const handleGetLocation = (trigger: LocationQueryTrigger, dispatch: AppDispatch) => (async (data: GetLocationByIdType) => {
  store.dispatch(preloaderChangeStatus(true))

  try {
    const response = await trigger(data).unwrap()
    store.dispatch(preloaderChangeStatus(false))

    return response
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
});

export const LocationInfo: FC = () => {
  const params = useParams();
  const intl = useIntl();
  const { activeBusiness, authInfo } = useAppSelector((state) => state.common);
  const realEstateId = authInfo?.realEstate?.id;
  const isRealEstate = useDefineIsRealEstateRole();

  const dispatch = useAppDispatch()
  const [trigger, response] = locationsApi.useLazyGetLocationByIdQuery()

  const permissions = ability.can('read', 'singlePages')
  const navigate = useNavigate()

  useEffect(() => {
    if(!permissions || !params.id){
      return navigate(`/404`);
    }
    const data: GetLocationByIdType = {
      locationId: +params.id,
      businessId: activeBusiness,
    };
    if (isRealEstate) {
      data.realEstateId = realEstateId;
    }
    handleGetLocation(trigger, dispatch)(data)
  }, [])
  return (
    <Box>
      <LocationInfoProvider value={{businessId: 1	}}>
        <PageHeader
          title={response.data?.name ?? ''}
          onlyTitle
        />
					
        {response.data && <Grid  // TODO: let take a look to reviewer
          container	// TODO: Add page loader
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >	
            <LocationDetails
              data={response.data}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={8}
          >
            {params.id && <ClientListInLocationDetails
              title={intl.formatMessage({id: 'clientsList'})}
              data={response.data.users}
              locationId={+params.id}
              businessId={response.data.business.id}
            />}
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
          >
            <LocationFiles
              title={intl.formatMessage({id: 'files'})}
              data={response.data}
            />
          </Grid>
        </Grid>}
      </LocationInfoProvider>
    </Box>
  );
};
