import { FC } from "react";
import {
  DataGrid
} from '@mui/x-data-grid';
import { TableProps } from "./Table.types";
import theme from "src/theme";
import { EmptyTable } from "../EmptyTable";

export const Table: FC<TableProps> = ({ rows, columns, handleSort, ...rest}) => {
  return (
    <DataGrid
      // checkboxSelection={rest.checkboxSelection ?? true}
      disableSelectionOnClick
      rows={rows}
      columns={columns}
      sortingMode={handleSort ? 'server' : 'client'}
      onSortModelChange={handleSort}
      disableColumnMenu
      components={{
        'NoRowsOverlay': EmptyTable
      }}
      {...rest}
      sx={{
        background: theme.palette.background.default,
        minHeight: theme.spacing(600/8),
        '& .MuiDataGrid-row:hover': {
          backgroundColor: 'inherit',
          boxShadow: '0px -1.75px 4px rgba(33, 33, 52, 0.039), 0px 5.75px 10px rgba(33, 33, 52, 0.12)'
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
          justifyContent: 'space-between',
        },
        '& .MuiDataGrid-cell.ampTableCell:focus': {
          outline: 'none'
        },
        '& .MuiDataGrid-cellContent': {
          lineHeight: '1.2'
        },
        '& .hoverableCell button': {
          opacity: 0,
          transition: '0.3s ease',
        },
        '& .justifyEnd': {
          justifyContent: 'flex-end'
        },
        '& .MuiDataGrid-row:hover .hoverableCell button': {
          opacity: 1
        },
        '& .MuiDataGrid-columnHeaderTitle, & .MuiDataGrid-columnHeaderTitleContainerContent': {
          color: theme.palette.neutral[400]
        },
        '& .PrivateSwitchBase-input + .MuiSvgIcon-root': {
          stroke: theme.palette.neutral[300]
        },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none'
        },
        ...rest.sx,
      }}
    />
  )
} 
