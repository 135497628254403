import { FC, useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { FormattedMessage } from "react-intl";
import { usersApi } from "src/api";
import { useAppSelector } from "src/app/hooks";
import { AxiosBaseQueryFn } from "src/axiosBaseQuery";
import {
  ListParamsType,
  PageBaseUsersInfo,
  UsersSearchRequest,
} from "src/types";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { ReManagersTable } from "src/components/common/ReOwner/ReManagersTable";
import { AddReManager } from "../../components/forms/AddReManager";
import { RoleEnum } from "src/enums";
import { useNavigate, useParams } from "react-router-dom";
import ability from "src/permissions/ability";
import { filterWrapperSx } from "./ReManager.sx";
import { DataFetchProps } from "./ReManager.types";

const handleGetData = (
  usersTrigger: LazyQueryTrigger<
    QueryDefinition<
      UsersSearchRequest,
      AxiosBaseQueryFn,
      "User" | "Team",
      PageBaseUsersInfo,
      "usersApi"
    >
  >,
  page: number,
  size: number,
  businessId: number,
  realEstateId: number,
  triggerLoadingFlag: () => void
) => {
  return async () => {
    triggerLoadingFlag();
    try {
      const usersResponse = await usersTrigger({
        data: {
          businessId,
          realEstateId,
          roles: [RoleEnum.REAL_ESTATE],
        },
        params: {
          page,
          size,
        },
      }).unwrap();

      return usersResponse;
    } catch (error) {
      if (isErrorObject(error)) {
        NotifyError(error.data.message);
      }
    } finally {
      triggerLoadingFlag();
    }
  };
};

export const ReManager: FC = () => {
  const theme = useTheme();

  const { id: reOwnerId } = useParams();

  const navigate = useNavigate();

  const permissions = ability.can("read", "reOwner");

  const [usersTrigger, usersResponse] = usersApi.useLazyGetUsersQuery();

  const { authInfo } = useAppSelector((state) => state.common);
  const businessId = authInfo?.business?.id;
  const isSuperAdmin = authInfo?.roles[0].role === RoleEnum.SUPER_ADMIN;

  const [tableParams, setTableParams] = useState<ListParamsType>({
    page: 0,
    size: 100,
  });
  const [business, setBusiness] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const callHandler = (businessId: number) =>
    handleGetData(
      usersTrigger,
      tableParams.page,
      tableParams.size,
      businessId,
      reOwnerId ? parseInt(reOwnerId) : 0,
      triggerLoadingFlag
    )();

  const triggerLoadingFlag = () => setIsLoading((prevState) => !prevState);

  const handleDataFetch = ({ business }: DataFetchProps) => {
    setBusiness(business);

    callHandler(business);
  };

  const initialDataFetch = async () => {
    try {
      if (!reOwnerId) {
        return;
      }

      handleDataFetch({
        business: businessId,
        reOwner: parseInt(reOwnerId),
      });
    } catch (error) {
      if (isErrorObject(error)) {
        NotifyError(error.data.message);
      }
    }
  };

  useEffect(() => {
    initialDataFetch();
  }, []);

  useEffect(() => {
    if (business === 0) {
      return;
    }

    callHandler(business);
  }, [tableParams]);

  useEffect(() => {
    if (!permissions) {
      return navigate("/404");
    }
  }, []);

  return (
    <>
      <Box sx={filterWrapperSx(theme)}>
        <Typography variant="h2">
          <FormattedMessage id="reManager" />
        </Typography>

        <Box>{!isSuperAdmin && <AddReManager />}</Box>
      </Box>

      <ReManagersTable
        data={usersResponse?.data?.content}
        total={usersResponse?.data?.totalPages ?? 0}
        isLoading={isLoading}
        tableParams={tableParams}
        setTableParams={setTableParams}
      />
    </>
  );
};
