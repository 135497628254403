import { Add, Delete, Edit } from "@mui/icons-material";
import { Box, Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { chargersApi } from "src/api";
import { useAppDispatch, useAppSelector, useDefineIsRealEstateRole } from "src/app/hooks";
import AMPModal from "src/components/common/AMPModal";
import { DropZoneModal } from "src/components/common/DropZoneModal";
import { TableIdCell } from "src/components/common/TableIdCell";
import { EditIcon } from "src/components/icons/EditIcon";
import { UserCell } from "src/components/users/UserCell";
import { Can } from "src/permissions/Can";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { AppDispatch } from "src/types";
import { blankPhoto } from "src/utils/consts";
import { ChargerResetWrapper } from "../ChargerResetWrapper";
import { ChargerUpdateFormWrapper } from "../ChargerUpdateFormWrapper";
import {
  detailsWrapperSx,
  detailsInnerSx,
  detailsTitleSx,
  imageWrapperSx,
  detailItemSx,
  buttonImageWrapperSx,
  imageButtonSx,
  editButtonSx,
  imageInnerSx,
  costBoxSx,
  editCostBtnSx,
  editCostIconSx,
  userBoxSx,
  deleteButtonSx,
  modalBodySx,
  modalFooterSx
} from "./ChargerDetails.sx";
import { ChargerDetailsType } from "./ChargerDetails.types";
import theme from "../../../theme";
import { generateItemCost, isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { EditChargerCostForm } from "../../forms/EditChargerCostForm";
import { CurrencyEnum } from "../../../enums";
import dayjs from "dayjs";
import { EditChargerCostFormType } from "../../forms/formData/editChargerCost";

type AddImageMutationTrigger = ReturnType<
  typeof chargersApi.useAddChargerImageMutation
>[0];

type DeleteUserMutationTrigger = ReturnType<
  typeof chargersApi.useDeleteUserMutation
>[0];


const handleTrigger = async (
  file: File[],
  chargerId: number,
  trigger: AddImageMutationTrigger,
  dispatch: AppDispatch,
  handleCloseImageModal: () => void,
  handleBlockSubmit: (status: boolean) => void,
  businessId?: number,
) => {
  try {
    handleBlockSubmit(true)
    dispatch(preloaderChangeStatus(true));
    const formData = new FormData()
    formData.append('file', file[0])
    formData.set('businessId', String(businessId))
    await trigger({ data: formData, chargerId })
      .unwrap()
    handleCloseImageModal()
  } catch (error) {
    if (isErrorObject(error)) {
      NotifyError(error.data.message)
    }
  } finally {
    handleBlockSubmit(false)
    dispatch(preloaderChangeStatus(false));
  }
};

const handleDeleteUser =  (
  deleteUser: DeleteUserMutationTrigger,
  userId: number,
  businessId: number,
  chargerId: number,
  closeModal: () => void
) => async () => {
  try {
    await deleteUser({
      userId,
      businessId,
      chargerId
    })

  } catch (error) {
    if (isErrorObject(error)) {
      NotifyError(error.data.message)
    }
  } finally {
    closeModal();
  }
}

export const ChargerDetails: FC<ChargerDetailsType> = ({ data }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const isRealEstate = useDefineIsRealEstateRole();
  
  const [open, setOpen] = useState<boolean>(false)
  const [openDropZone, setOpenDropZone] = useState<boolean>(false)
  const [chargerImage, setChargerImage] = useState<File[]>()
  const [showEditCost, setShowEditCost] = useState(false)
  const [blockSubmit, setBlockSubmit] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const cost = data?.itemCosts?.[0]

  const costInitValue: EditChargerCostFormType = {
    cost: cost ? cost?.currentCost : 0,
    currency: cost ? cost?.currency : CurrencyEnum.EUR,
    date: cost ? cost?.installationDate : dayjs().valueOf(),
    serviceLife: cost ? cost?.serviceLife : 0
  }

  const dialogTitle = intl.formatMessage({ id: 'editCharger' })
  const uploadDialogTitle = intl.formatMessage({ id: 'addNewImage' })
  const { authInfo } = useAppSelector((state) => state.common);
  const [trigger] = chargersApi.useAddChargerImageMutation();
  const [deleteUser] = chargersApi.useDeleteUserMutation();



  const handleShowDeleteUserModal = (status: boolean) => () => {
    setShowDeleteModal(status)
  }

  const handleShowEditCostModal = (status: boolean) => () => {
    setShowEditCost(status)
  }

  const handleBlockSubmit = (status: boolean) => {
    setBlockSubmit(status)
  }

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const handleOpenImageModal = () => {
    setOpenDropZone(true)
  }

  const handleCloseImageModal = () => {
    setOpenDropZone(false)
  }

  const handleSetImage = (file: File[] | null) => {
    file && setChargerImage(file)
  }

  const handleSubmit = () => {
    chargerImage &&
      handleTrigger(chargerImage, data.id, trigger, dispatch, handleCloseImageModal, handleBlockSubmit, data.business.id)
  }

  return (
    <>
      <Grid
        container
        spacing={0}
        sx={detailsWrapperSx}
      >
        <Grid
          item
          xs={12}
          sx={detailsInnerSx}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              minHeight={theme.spacing(200 / 8)}
              xs={12}
              lg={4}
              sx={imageWrapperSx}
            >
              <Box sx={imageInnerSx}>
                <Can
                  I="manage"
                  a="charger"
                >
                  <Box sx={buttonImageWrapperSx}>
                    <IconButton
                      onClick={handleOpenImageModal}
                      sx={imageButtonSx}
                    >
                      <Edit />
                    </IconButton>
                  </Box>
                  <DropZoneModal
                    submitIsBlocked={blockSubmit}
                    handleClose={handleCloseImageModal}
                    open={openDropZone}
                    title={uploadDialogTitle}
                    handleFile={handleSetImage}
                    handleSubmit={handleSubmit}
                    isImage
                  />
                </Can>
                <img
                  src={data.image?.url ?? blankPhoto}
                  alt={`${data?.chargePointModel} ${data?.chargePointSerialNumber}`}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              lg={8}
              position="relative"
            >
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={10}
                  lg={6}
                >
                  <Typography variant="subtitle1">
                    <FormattedMessage id="chargerDetails" />
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  lg={6}
                >
                  <Box sx={detailsTitleSx}>
                    {!isRealEstate && (
                      <Can
                        I="manage"
                        a="charger"
                      >
                        <IconButton
                          sx={editButtonSx}
                          onClick={handleOpenModal}
                        >
                          <EditIcon />
                        </IconButton>
                      </Can>
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={7}
                  sx={detailItemSx}
                >
                  <Typography
                    variant="caption"
                  >
                    <FormattedMessage id="id" />
                  </Typography>
                  <TableIdCell
                    businessId={data.business.id}
                    label={data.chargePointId}
                    shownButton
                  />
                  <Typography
                    variant="caption"
                  >
                    <FormattedMessage id="client" />
                  </Typography>
                  
                  {data.user
                    ? 
                    <Box sx={userBoxSx}>
                      <UserCell data={data.user} />
                      {!isRealEstate && (
                        <Can
                          I="delete"
                          a="deleteUserStation"
                        >
                          <IconButton
                            onClick={handleShowDeleteUserModal(true)}
                            sx={deleteButtonSx}
                          >
                            <Delete />
                          </IconButton>
                        </Can>
                      )}
                      
                    </Box>
                    : <Typography variant="subtitle2">-</Typography>
                  }
                  <Typography
                    variant="caption"
                  >
                    <FormattedMessage id="business" />
                  </Typography>
                  {data.business &&
                    <Typography variant="subtitle2">
                      {data.business.name}
                    </Typography>
                  }
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  sx={detailItemSx}
                >
                  <Can
                    I="read"
                    a="costData"
                  >
                    <Typography
                      variant="caption"
                    >
                      <FormattedMessage id="currentCostCharger" />
                    </Typography>
                    <Box sx={costBoxSx}>
                      <Typography
                        variant="subtitle2"
                      >
                        {generateItemCost(data.itemCosts)}
                      </Typography>
                      {!isRealEstate && !!data.itemCosts?.length &&
                      <IconButton
                        onClick={handleShowEditCostModal(true)}
                        sx={editCostBtnSx}
                      >
                        <Edit sx={editCostIconSx} />
                      </IconButton>
                      }
                      {!isRealEstate && (
                        <Can
                          I="manage"
                          a="costCharger"
                        >
                          {!data.itemCosts?.length &&
                        <IconButton
                          onClick={handleShowEditCostModal(true)}
                          sx={editCostBtnSx}
                        >
                          <Add sx={editCostIconSx} />
                        </IconButton>
                          }
                        </Can>
                      )}
                    </Box>
                  </Can>
                  <Typography
                    variant="caption"
                  >
                    <FormattedMessage id="location" />
                  </Typography>
                  <Typography
                    variant="subtitle2"
                  >
                    {data.location?.name || <FormattedMessage id="noLocation" /> }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!isRealEstate && (
          <Can
            I="manage"
            a="resetChargers"
          >
            <ChargerResetWrapper data={data} />
          </Can>
        )}
      </Grid>
      <AMPModal
        handleClose={handleShowDeleteUserModal(false)}
        open={showDeleteModal}
        title={intl.formatMessage({id: 'deleteUser'})}
        type="md"
        fullWidth
        withoutPaddings
      >
        <Box sx={modalBodySx}>
          <Typography>
            {intl.formatMessage({id: 'deleteNameUser'}, {name: `${data?.user?.firstName} ${data?.user?.lastName}`})}
          </Typography>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteUser(
              deleteUser,
              data.user?.id,
              data.business?.id,
              data?.id,
              handleShowDeleteUserModal(false) 
            )}
          >
            {intl.formatMessage({id: 'deleteUser'})}
          </Button>
        </Box>
      </AMPModal>
      <AMPModal
        handleClose={handleShowEditCostModal(false)}
        open={showEditCost}
        title={intl.formatMessage({id: "currentCostCharger"})}
        type="md"
        fullWidth
        withoutPaddings
      >
        <EditChargerCostForm
          onClose={handleShowEditCostModal(false)}
          init={costInitValue}
          chargerId={data.id}
          businessId={authInfo?.business?.id}
          itemId={cost?.id}
          closeModal={handleShowEditCostModal(false)}
        />
      </AMPModal>
      <AMPModal
        handleClose={handleCloseModal}
        open={open}
        title={dialogTitle}
        type="md"
        fullWidth
        withoutPaddings
      >
        <ChargerUpdateFormWrapper
          handleClose={handleCloseModal}
          authInfo={authInfo}
          initialValue={data}
        />
      </AMPModal>
    </>
  );
};
