import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { reOwnerApi } from "src/api";
import { useAppSelector } from "src/app/hooks";
import { AppRouteEnum, AppRouteSpace, isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { tableFooterSx, wrapperSx } from "./ReOwnersTable.sx";
import { TableRowCounter } from "src/components/tables/TableRowCounter";
import { TablePagination } from "src/components/tables/TablePagination";
import { ListParamsType } from "src/types";
import { ResponsiveTable } from "../../ResponsiveTable";
import { TableCard } from "src/components/tables/respnsiveTable/TableCard";
import { TableRow } from "src/components/tables/respnsiveTable/TableRow";
import { Table } from "src/components/tables/Table";
import { GridColumns } from "@mui/x-data-grid";
import { FormattedMessage } from "react-intl";
import { TableIdCell } from "../../TableIdCell";
import { ReOwnersTableProps } from "./ReOwnersTable.types";

const calculateColumnReOwners = (businessId: number): GridColumns => {
  return [
    {
      field: "name",
      renderHeader: (_params) => <FormattedMessage id={"name"} />,
      flex: 2,
      minWidth: 200,
      sortable: false,
      cellClassName: "ampTableCell",
      renderCell: (params) => (
        <TableIdCell
          label={params.row.name}
          link={AppRouteSpace([
            AppRouteEnum.app,
            AppRouteEnum.reOwner,
            params.row.id,
          ])}
          businessId={businessId}
        />
      ),
    },
  ];
};

export const ReOwnersTable: FC<ReOwnersTableProps> = ({ isDialogVisible }) => {
  const theme = useTheme();

  const { authInfo } = useAppSelector((state) => state.common);
  const businessId = authInfo?.business?.id;

  const [reOwnersTrigger, reOwnersData] = reOwnerApi.useLazyGetReOwnersQuery();

  const [tableParams, setTableParams] = useState<ListParamsType>({
    page: 0,
    size: 100,
  });

  const initialDataFetch = async () => {
    try {
      await reOwnersTrigger({
        params: {
          size: tableParams.size,
          page: tableParams.page,
        },
        data: {
          businessId,
        },
      }).unwrap();
    } catch (error) {
      if (isErrorObject(error)) {
        NotifyError(error.data.message);
      }
    }
  };

  const handleChangePage = (e: number) => {
    setTableParams((prevState) => ({
      ...prevState,
      page: e,
    }));
  };

  const handlePerPageChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const val = +e.target.value;
    if (typeof val === "number") {
      setTableParams({ size: val, page: 0 });
    }
  };

  useEffect(() => {
    if (!isDialogVisible) {
      initialDataFetch();
    }
  }, [tableParams, isDialogVisible]);

  return (
    <Box sx={wrapperSx(theme)}>
      <ResponsiveTable
        responsiveElement={
          <>
            {reOwnersData?.data?.content?.map((reOwner) => (
              <TableCard key={reOwner.id}>
                <TableRow titleId="name" value={reOwner.name} />
              </TableCard>
            ))}
          </>
        }
        desktopElement={
          <Table
            loading={reOwnersData?.isLoading}
            rows={reOwnersData?.data?.content ?? []}
            hideFooter
            columns={calculateColumnReOwners(businessId)}
          />
        }
      />

      <Box sx={tableFooterSx(theme)}>
        <TableRowCounter
          handleChangeRowsNumber={handlePerPageChange}
          defaultValue={tableParams.size}
        />
        <TablePagination
          totalPages={reOwnersData?.data?.totalPages ?? 0}
          page={tableParams.page}
          handleChangePage={handleChangePage}
        />
      </Box>
    </Box>
  );
};
