import { Box, Checkbox, FormControlLabel, Grid, SelectChangeEvent, Typography } from "@mui/material";
import { Form, Formik, FormikConfig, FormikHandlers, FormikProps } from "formik";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { businessesApi, registerApi } from "src/api";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { AppDispatch, store } from "src/app/store";
import { AMPFormSelect } from "src/components/common/AMPFormSelect";
import { AMPInput } from "src/components/common/AMPInput";
import { CancelButton } from "src/components/common/CancelButton";
import { SubmitButton } from "src/components/common/SubmitButton";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { getHelperText } from "src/utils/forms";
import { isErrorObject, processedLanguages, valuesTrimmer } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import {
  AddUserInitialValue,
  titles
} from "../formData/common/initialData";
import { userFormSchema, UserFormType } from "../formData/user";
import {
  AutocompleteValue,
  ManualDriverRegistrationRequest,
  SelectValue,
} from "../../../types";
import { TableFilterFormTypes } from "../../reports/TableFilter/TableFilter.types";
import { RoleEnum } from "../../../enums";
import { AMPAutocomplete } from "../../common/AMPAutocomplete";
import { findAutocompleteValueById } from "../../../utils/helper";
import { AMPAutocompleteProps } from "../../common/AMPAutocomplete/AMPAutocomplete.types";
import { TariffType } from "../formData/tariff";
import { actionsWrapperSx, formSx } from "./UserForm.sx";
import { UserFormTypes } from "./UserForm.types";
import { AMPPhoneNumber } from "../../common/AMPPhoneNumber";
import { systemApi } from "../../../api/system";
import { handleGetCountries } from "../../../hooks/useGetCountries";
import { allRegionsValue } from "../../../utils/consts";

type AddUserMutationTrigger = ReturnType<
  typeof registerApi.useAddNewUserMutation
>[0];
type BusinessesQueryTrigger = ReturnType<typeof businessesApi.useLazyGetBusinessesQuery>[0];

const handleSubmit =
  (
    trigger: AddUserMutationTrigger,
    dispatch: AppDispatch,
    handleClose: () => void,
    businessId?: number,
  ): FormikConfig<UserFormType>["onSubmit"] =>
    async (values) => {
      const value = valuesTrimmer(values)
      try {
        dispatch(preloaderChangeStatus(true));
        const data: ManualDriverRegistrationRequest = {
          homeAddress: {
            region: value.regionHome === allRegionsValue ? undefined : value.regionHome,
            country: value.countryHome,
            city: value.cityHome,
            street: value.streetHome,
            zipCode: value.zipCodeHome,
            houseNumber: value.houseNumberHome,
          },
          parkingAddress: {
            region: value.regionParking === allRegionsValue ? undefined : value.regionParking,
            country: value.countryParking,
            city: value.cityParking,
            street: value.streetParking,
            zipCode: value.zipCodeParking,
            houseNumber: value.houseNumberParking,
            parkingPlace: value.parkingLotParking.toString() ?? ''
          },
          businessId,
          emailAddress: value.emailAddress,
          firstName: value.firstName,
          lastName: value.lastName,
          phoneNumber: value.phoneNumber,
          title: value.title,
          language: value.language,
        };
        await trigger(data)
          .unwrap()
        handleClose()
      } catch (error) {
        if(isErrorObject(error)){
          NotifyError(error.data.message)
        }
      } finally {
        dispatch(preloaderChangeStatus(false));
      }
    };

export const getBusinessesData = async (trigger: BusinessesQueryTrigger) => {
  try {
    store.dispatch(preloaderChangeStatus(true))
    await trigger({
      params: {
        size: 2000
      }
    })
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false))
  }
}

export const UserForm: FC<UserFormTypes> = ({ businessId, handleClose }) => {
  const intl = useIntl();
  const {authInfo} = useAppSelector(state => state.common)
  const [trigger] = registerApi.useAddNewUserMutation();
  const [addressBlock, setAddressBlock] = useState<boolean>(false)
  const [getBusinesses, businesses] = businessesApi.useLazyGetBusinessesQuery()
  const isSuperAdmin = authInfo?.roles?.[0]?.role === RoleEnum.SUPER_ADMIN
  const [business, setBusiness] = useState<number | undefined>(businessId)
  const [getCountries, countries] = systemApi.useLazyGetCountriesQuery()
  const dispatch = useAppDispatch()
  const [activeCountryParking, setActiveCountryParking] = useState<string>('')
  const [activeCountryHome, setActiveCountryHome] = useState<string>('')
  
  const processedRegionsParking = useMemo(() => {
    const initialArray: SelectValue[] = [{
      id: 0,
      name: activeCountryParking.length ? intl.formatMessage({id: activeCountryParking.toLowerCase()}) : '',
      value: allRegionsValue
    }]
    if (activeCountryParking.length) {
      const mappedRegions: SelectValue[] | undefined = countries.data?.find(country => country.name === activeCountryParking)?.
        regions?.map(region => ({
          id: region.id,
          name: intl.formatMessage({id: region.name}),
          value: region.name
        }))
      return  mappedRegions?.concat(initialArray[0])
        ?? initialArray
    }
    return initialArray
  }, [activeCountryParking])
  
  const processedRegionsHome = useMemo(() => {
    const initialArray: SelectValue[] = [{
      id: 0,
      name: activeCountryHome.length ? intl.formatMessage({id: activeCountryHome.toLowerCase()}) : '',
      value: allRegionsValue
    }]
    if (activeCountryHome.length) {
      const mappedRegions: SelectValue[] | undefined = countries.data?.find(country => country.name === activeCountryHome)?.
        regions?.map(region => ({
          id: region.id,
          name: intl.formatMessage({id: region.name}),
          value: region.name
        }))
      return  mappedRegions?.concat(initialArray[0])
        ?? initialArray
    }
    return initialArray
  }, [activeCountryHome])

  const processedCountries = countries?.data?.map(country => ({
    id: country.id,
    name: intl.formatMessage({id: country.name.toLowerCase()}),
    value: country.name
  })) ?? []
  
  useEffect(() => {
    isSuperAdmin && getBusinessesData(getBusinesses)
    handleGetCountries(getCountries, dispatch)
  }, []);
  
  const toggleAddressBlock = (
    handleChange: FormikHandlers["handleChange"],
    setFieldValue: FormikProps<TableFilterFormTypes>['setFieldValue'],
    values: UserFormType
  ) => (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setAddressBlock(checked)
    handleChange(event)
    setActiveCountryHome(activeCountryParking)
    setFieldValue('useParkingAddress', checked)
    setFieldValue('countryHome', values.countryParking)
    setFieldValue('regionHome', values.regionParking)
    setFieldValue('cityHome', values.cityParking)
    setFieldValue('streetHome', values.streetParking)
    setFieldValue('zipCodeHome', values.zipCodeParking)
    setFieldValue('houseNumberHome', values.houseNumberParking)
  }
  
  const handleChangeCountryParking = (
    handleChange: FormikHandlers["handleChange"],
    setFieldValue: FormikProps<TariffType>['setFieldValue']
  ) => (event: SelectChangeEvent) => {
    setActiveCountryParking(event.target.value);
    setFieldValue('regionParking', '')
    return handleChange(event);
  };
  
  const handleChangeCountryHome = (
    handleChange: FormikHandlers["handleChange"],
    setFieldValue: FormikProps<TariffType>['setFieldValue']
  ) => (event: SelectChangeEvent) => {
    setActiveCountryHome(event.target.value);
    setFieldValue('regionHome', '')
    return handleChange(event);
  };
  
  const processedBusinesses = ():AutocompleteValue[] => {
    if (businesses?.data?.content) {
      return businesses.data.content.map(item => ({
        id: item?.id,
        label: item?.name,
        image: item?.logo?.url ?? ""
      }))
    }
    return [{
      id: authInfo?.business?.id ?? 0,
      label: authInfo?.business?.name ?? '',
    }]
  }
  
  const handleChangeBusinessValue = (setFieldValue: FormikProps<TariffType>['setFieldValue'], fieldName: string): AMPAutocompleteProps['onChange'] =>
    (e, newValue) => {
      if(newValue){
        setFieldValue(fieldName, newValue.id)
        setBusiness(newValue.id)
      }
    }
  
  const handleChangePhone = (setFieldValue: FormikProps<TariffType>['setFieldValue'], fieldName: string) =>
    (newValue: string | number | null | undefined) => {
      if(newValue){
        setFieldValue(fieldName, `+${newValue}`)
      }
    }
    
  return (
    <Formik
      initialValues={isSuperAdmin ? AddUserInitialValue : {...AddUserInitialValue, businessId: business ?? 0}}
      validationSchema={userFormSchema}
      onSubmit={handleSubmit(trigger, dispatch, handleClose, business)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }: FormikProps<UserFormType>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box
            sx={formSx}
          >
            <Box>
              <Typography variant="subtitle1"
                color="textPrimary">
                <FormattedMessage id="generalInfo" />
              </Typography>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{xs: 0, md: 2}}
              >
                <Grid
                  xs={12}
                  lg={6}
                  item
                  sx={{
                    visibility: isSuperAdmin ? "visible" : "hidden",
                    height: isSuperAdmin ? "auto" : 0
                  }}
                >
                  <AMPAutocomplete
                    options={processedBusinesses()}
                    value={isSuperAdmin
                      ? findAutocompleteValueById(processedBusinesses(), +values['businessId'])
                      : processedBusinesses()[0]
                    }
                    label={'business'}
                    input={'businessId'}
                    showAvatar
                    onChange={handleChangeBusinessValue(setFieldValue, 'businessId')}
                    onBlur={handleBlur}
                    hasError={
                      (touched['businessId'] && Boolean(errors['businessId'])) ||
                      (touched['businessId'] && values['businessId'] === 0)
                    }
                    helperText={getHelperText('businessId', touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  lg={3}
                  xs={12}
                  item
                >
                  <AMPFormSelect
                    data={titles(intl)}
                    value={values["title"] as string}
                    label={"title"}
                    input={"title"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["title"] && Boolean(errors["title"])
                    }
                    helperText={getHelperText("title", touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  lg={4}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["firstName"]}
                    type={"firstName"}
                    label={"firstName"}
                    input={"firstName"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["firstName"] && Boolean(errors["firstName"])
                    }
                    helperText={getHelperText("firstName", touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  lg={5}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["lastName"]}
                    type={"lastName"}
                    label={"lastName"}
                    input={"lastName"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched["lastName"] && Boolean(errors["lastName"])}
                    helperText={getHelperText("lastName", touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["emailAddress"]}
                    type={"emailAddress"}
                    label={"emailAddress"}
                    input={"emailAddress"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["emailAddress"] && Boolean(errors["emailAddress"])
                    }
                    helperText={getHelperText(
                      "emailAddress",
                      touched,
                      errors,
                      intl
                    )}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPPhoneNumber
                    value={values["phoneNumber"]}
                    onChange={handleChangePhone(setFieldValue, "phoneNumber")}
                    label={"phoneNumber"}
                    onBlur={handleBlur}
                    hasError={
                      touched["phoneNumber"] && Boolean(errors["phoneNumber"])
                    }
                    helperText={getHelperText("phoneNumber", touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["bexioId"]}
                    type={"number"}
                    label={"bexioId"}
                    input={"bexioId"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["bexioId"] && Boolean(errors["bexioId"])
                    }
                    helperText={getHelperText(
                      "bexioId",
                      touched,
                      errors,
                      intl
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Typography variant="subtitle1"
                color="textPrimary">
                <FormattedMessage id="parkingAddress" />
              </Typography>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPFormSelect
                    data={processedCountries}
                    value={values["countryParking"] as string}
                    label={"country"}
                    input={"countryParking"}
                    onChange={handleChangeCountryParking(handleChange, setFieldValue)}
                    onBlur={handleBlur}
                    hasError={touched["countryParking"] && Boolean(errors["countryParking"])}
                    helperText={getHelperText("countryParking", touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPFormSelect
                    data={processedRegionsParking}
                    value={values["regionParking"] as string}
                    label={"region"}
                    input={"regionParking"}
                    disabled={!activeCountryParking.length}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched["regionParking"] && Boolean(errors["regionParking"])}
                    helperText={getHelperText("regionParking", touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    type='text'
                    disabled={Boolean(!activeCountryParking)}
                    value={values['cityParking']}
                    label={'city'}
                    input={'cityParking'}
                    placeholder='city'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched['cityParking'] && Boolean(errors['cityParking'])}
                    helperText={getHelperText('cityParking', touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["streetParking"] as string}
                    type={"text"}
                    label={"street"}
                    placeholder="street"
                    input={"streetParking"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched["streetParking"] && Boolean(errors["streetParking"])}
                    helperText={getHelperText("streetParking", touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["houseNumberParking"] as string}
                    type={"text"}
                    label={"houseNumber"}
                    input={"houseNumberParking"}
                    placeholder="houseNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={
                      touched["houseNumberParking"] && Boolean(errors["houseNumberParking"])
                    }
                    helperText={getHelperText(
                      "houseNumberParking",
                      touched,
                      errors,
                      intl
                    )}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["zipCodeParking"] as string}
                    type={"number"}
                    label={"zipCode"}
                    placeholder="zipCode"
                    input={"zipCodeParking"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched["zipCodeParking"] && Boolean(errors["zipCodeParking"])}
                    helperText={getHelperText("zipCodeParking", touched, errors, intl)}
                  />
                </Grid>
                <Grid
                  lg={6}
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values["parkingLotParking"] as string}
                    type={"text"}
                    placeholder="parkingLot"
                    label={"parkingLot"}
                    input={"parkingLotParking"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched["parkingLotParking"] && Boolean(errors["parkingLotParking"])}
                    helperText={getHelperText("parkingLotParking", touched, errors, intl)}
                  />
                </Grid>
              </Grid>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                sx={{pt: 2}}
              >
                <FormattedMessage id="homeAddress" />
              </Typography>
              <FormControlLabel
                label={intl.formatMessage({id: "sameAddress"})}
                control={
                  <Checkbox
                    color="secondary"
                    name="useParkingAddress"
                    value={values['useParkingAddress']}
                    onChange={toggleAddressBlock( handleChange,  setFieldValue, values)}
                  />
                }
              />
              {
                !addressBlock &&
                <>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      lg={6}
                      xs={12}
                      item
                    >
                      <AMPFormSelect
                        data={processedCountries}
                        value={values["countryHome"] as string}
                        label={"country"}
                        input={"countryHome"}
                        onChange={handleChangeCountryHome(handleChange, setFieldValue)}
                        onBlur={handleBlur}
                        hasError={touched["countryHome"] && Boolean(errors["countryHome"])}
                        helperText={getHelperText("country", touched, errors, intl)}
                      />
                    </Grid>
                    <Grid
                      lg={6}
                      xs={12}
                      item
                    >
                      <AMPFormSelect
                        data={processedRegionsHome}
                        value={values["regionHome"] as string}
                        label={"region"}
                        disabled={!activeCountryHome.length}
                        input={"regionHome"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        hasError={touched["regionHome"] && Boolean(errors["regionHome"])}
                        helperText={getHelperText("region", touched, errors, intl)}
                      />
                    </Grid>
                    <Grid
                      lg={6}
                      xs={12}
                      item
                    >
                      <AMPInput
                        type='text'
                        disabled={Boolean(!activeCountryHome)}
                        value={values['cityHome']}
                        label={'city'}
                        placeholder="city"
                        input={'cityHome'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        hasError={touched['cityHome'] && Boolean(errors['cityHome'])}
                        helperText={getHelperText('cityHome', touched, errors, intl)}
                      />
                    </Grid>
                    <Grid
                      lg={6}
                      xs={12}
                      item
                    >
                      <AMPInput
                        value={values["streetHome"] as string}
                        type={"text"}
                        label={"street"}
                        placeholder="street"
                        input={"streetHome"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        hasError={touched["streetHome"] && Boolean(errors["streetHome"])}
                        helperText={getHelperText("street", touched, errors, intl)}
                      />
                    </Grid>
                    <Grid
                      lg={6}
                      xs={12}
                      item
                    >
                      <AMPInput
                        value={values["houseNumberHome"] as string}
                        type={"text"}
                        label={"houseNumber"}
                        placeholder={"houseNumber"}
                        input={"houseNumberHome"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        hasError={
                          touched["houseNumberHome"] && Boolean(errors["houseNumberHome"])
                        }
                        helperText={getHelperText(
                          "houseNumberHome",
                          touched,
                          errors,
                          intl
                        )}
                      />
                    </Grid>
                    <Grid
                      lg={6}
                      xs={12}
                      item
                    >
                      <AMPInput
                        value={values["zipCodeHome"] as string}
                        type={"number"}
                        label={"zipCode"}
                        placeholder={"zipCode"}
                        input={"zipCodeHome"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        hasError={touched["zipCodeHome"] && Boolean(errors["zipCodeHome"])}
                        helperText={getHelperText("zipCodeHome", touched, errors, intl)}
                      />
                    </Grid>
                  </Grid>
                </>
              }
              <AMPFormSelect
                data={processedLanguages(intl)}
                value={values["language"] as string}
                label={"language"}
                input={"language"}
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={touched["language"] && Boolean(errors["language"])}
                helperText={getHelperText("language", touched, errors, intl)}
              />
            </Box>
          </Box>
          <Box sx={actionsWrapperSx}>
            <CancelButton
              text="cancel"
              handler={handleClose}
            />
            <SubmitButton
              text={"createUser"}
              color="primary"
              isSubmitting={isSubmitting}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};
