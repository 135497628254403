import { Box } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { ResponsiveTable } from "../../ResponsiveTable";
import { TableCard } from "src/components/tables/respnsiveTable/TableCard";
import { Table } from "src/components/tables/Table";
import { FormattedMessage } from "react-intl";
import { GridColumns } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import { TableRowCounter } from "src/components/tables/TableRowCounter";
import { TablePagination } from "src/components/tables/TablePagination";
import { TableRow } from "src/components/tables/respnsiveTable/TableRow";
import { DeleteUser } from "src/components/users/DeleteUser";
import { ReManagersTableProps } from "./ReManagersTable.types";
import { tableFooterSx, wrapperSx } from "./ReManagersTable.sx";
import { useAppSelector } from "src/app/hooks";
import { RoleEnum } from "src/enums";

const columnConfigUsers: GridColumns = [
  {
    field: "firstName",
    renderHeader: (_params) => <FormattedMessage id={"firstName"} />,
    flex: 2,
    minWidth: 200,
    sortable: false,
    cellClassName: "ampTableCell",
    renderCell: (params) => <Box>{params.row.firstName}</Box>,
  },
  {
    field: "lastName",
    renderHeader: (_params) => <FormattedMessage id={"lastName"} />,
    flex: 2,
    minWidth: 200,
    sortable: false,
    cellClassName: "ampTableCell",
    renderCell: (params) => <Box>{params.row.lastName}</Box>,
  },
  {
    field: "emailAddress",
    renderHeader: (_params) => <FormattedMessage id={"emailAddress"} />,
    flex: 2,
    minWidth: 200,
    sortable: false,
    cellClassName: "ampTableCell",
    renderCell: (params) => <Box>{params.row.emailAddress}</Box>,
  },
  {
    field: "delete",
    flex: 1,
    headerName: "",
    sortable: false,
    cellClassName: "ampTableCell hoverableCell justifyEnd",
    renderCell: (params) =>
      params.row.business && <DeleteUser row={params.row} />,
  },
];

export const ReManagersTable: FC<ReManagersTableProps> = ({
  data,
  isLoading,
  total,
  tableParams,
  setTableParams,
}) => {
  const theme = useTheme();

  const { roles } = useAppSelector((state) => state.common.authInfo);
  const isSuperAdmin = roles[0].role === RoleEnum.SUPER_ADMIN;

  const handlePerPageChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const val = +e.target.value;
    if (typeof val === "number") {
      setTableParams({ size: val, page: 0 });
    }
  };

  const handleChangePage = (e: number) => {
    setTableParams((prevState) => ({
      ...prevState,
      page: e,
    }));
  };

  const getColumnConfigUsers = (): GridColumns => {
    if (isSuperAdmin) {
      const modifiedColumnConfigUsers = [...columnConfigUsers];
      modifiedColumnConfigUsers.pop();

      return modifiedColumnConfigUsers;
    }

    return columnConfigUsers;
  };

  if (!data) {
    return null;
  }

  return (
    <Box sx={wrapperSx(theme)}>
      <ResponsiveTable
        responsiveElement={
          <>
            {data.map((item) => (
              <TableCard key={item.id}>
                <TableRow
                  titleId="firstName"
                  value={item.firstName}
                />
                <TableRow
                  titleId="lastName"
                  value={item.lastName}
                />
                <TableRow
                  titleId="emailAddress"
                  value={item.emailAddress}
                />
              </TableCard>
            ))}
          </>
        }
        desktopElement={
          <Table
            loading={isLoading}
            rows={data}
            hideFooter
            columns={getColumnConfigUsers()}
          />
        }
      />

      <Box sx={tableFooterSx(theme)}>
        <TableRowCounter
          handleChangeRowsNumber={handlePerPageChange}
          defaultValue={tableParams.size}
        />
        <TablePagination
          totalPages={total}
          page={tableParams.page}
          handleChangePage={handleChangePage}
        />
      </Box>
    </Box>
  );
};
