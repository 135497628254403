import { FC, useState } from "react";
import { Box } from "@mui/material";
import { GridSortItem, GridSortModel } from "@mui/x-data-grid";
import { TablePagination } from "src/components/tables/TablePagination";
import { TableRowCounter } from "src/components/tables/TableRowCounter";
import { LocationSortFieldEnum } from "src/enums/locations";
import { Table } from "../../tables/Table";
import { LocationsTableActions } from "../LocationsTableActions";
import { columnConfigLocations } from "./LocationTable.tableConfig";
import { LocationItemCard } from "./LocationItemCard";
import { LocationFilterValue } from "../LocationsTableActions/LocationsTableActions.types";
import { LocationTableProps } from "./LocationTable.types";
import { tableFooterSx } from "./LocationTable.sx";
import { ResponsiveTable } from "../../common/ResponsiveTable";
import ability from "../../../permissions/ability";
import { useDefineIsRealEstateRole } from "src/app/hooks";

export const LocationTable: FC<LocationTableProps> = ({
  data,
  size,
  pageNumber,
  handleUpdateTableData,
  handleChangePage,
  handleChangeRowsNumber,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [filterValue, setFilterValue] = useState<LocationFilterValue>({
    country: "",
    city: "",
    businessId: 0,
  });
  const [sortValue, setSortValue] = useState<GridSortItem>({
    field: "",
    sort: "asc",
  });
  const canCreateDeleteLocation = ability.can("manage", "location");

  const isRealEstate = useDefineIsRealEstateRole();

  const handleSearch = (value: string) => {
    setSearchValue(value);
    handleUpdateTableData(updateData(value, sortValue, filterValue));
  };

  const handleFilter = (value: LocationFilterValue) => {
    setFilterValue(value);
    handleUpdateTableData(updateData(searchValue, sortValue, value));
  };

  const handleSort = (value: GridSortModel) => {
    setSortValue(value[0]);
    handleUpdateTableData(updateData(searchValue, value[0], filterValue));
  };

  const updateData = (
    search: string,
    sort: GridSortItem,
    filter: LocationFilterValue
  ) => {
    return {
      businessId: filter.businessId,
      country: filter.country,
      city: filter.city,
      keyword: search,
      sortAsc: sort ? sort.sort === "asc" : true,
      sortField: (sort && sort.field) || LocationSortFieldEnum.ID,
    };
  };

  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <LocationsTableActions
        handleSearch={handleSearch}
        handleFilter={handleFilter}
      />
      <ResponsiveTable
        desktopElement={
          <Table
            rows={data.content}
            hideFooter
            columns={columnConfigLocations(canCreateDeleteLocation && !isRealEstate)}
            handleSort={handleSort}
          />
        }
        responsiveElement={
          <Box>
            {data.content.map((item) => (
              <LocationItemCard
                key={item?.id}
                canCreateDeleteLocation={canCreateDeleteLocation && !isRealEstate}
                {...item}
              />
            ))}
          </Box>
        }
      />
      <Box sx={tableFooterSx}>
        <TableRowCounter
          handleChangeRowsNumber={handleChangeRowsNumber}
          defaultValue={size}
        />
        <TablePagination
          totalPages={data.totalPages}
          page={pageNumber && pageNumber === 0 ? pageNumber : data.number}
          handleChangePage={handleChangePage}
        />
      </Box>
    </Box>
  );
};
