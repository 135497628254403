import { GridColumns, GridValueGetterParams } from '@mui/x-data-grid';
import { FormattedMessage } from 'react-intl';
import { ColoredStatus } from 'src/components/common/ColoredStatus';
import { PopoverTypography } from 'src/components/common/PopoverTypography';
import { StatusField } from 'src/components/common/StatusField';
import { TableIdCell } from 'src/components/common/TableIdCell';
import { ChargerSortFieldEnum, RoleEnum } from 'src/enums';
import { AppRouteEnum, AppRouteSpace } from 'src/utils/helper';
import { DeleteCharger } from '../DeleteCharger';
import dayjs from "dayjs";

export const columnConfigChargers = (role: RoleEnum, canRemove = true): GridColumns => ([
  {
    field: "chargePointId",
    renderHeader: (params) => <FormattedMessage id={"chargePointIdColumnHeader"} />,
    flex: 2,
    minWidth: 150,
    sortable: false,
    cellClassName: 'ampTableCell',
    renderCell: (params) => {
      return (
        <TableIdCell
          businessId={params.row.business.id}
          label={params.row.chargePointId}
          link={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.chargers, params.row.id])}
        />
      )
    }
  },
  {
    field: ChargerSortFieldEnum.CHARGE_POINT_STATUS,
    renderHeader: (params) => <FormattedMessage id={"stationStatusColumnHeader"} />,
    flex: 3,
    minWidth: 100,
    cellClassName: 'ampTableCell',
    renderCell: (params) => {
      return (
        <StatusField
          label={params.row.currentStatus}
        />
      )
    }
  },
  {
    field: ChargerSortFieldEnum.CONNECTOR_POINT_STATUS,
    renderHeader: (params) => <FormattedMessage id={"connectorStatusColumnHeader"} />,
    flex: 3,
    minWidth: 180,
    cellClassName: 'ampTableCell',
    renderCell: (params) => (
      params.row.connectors?.length ? <ColoredStatus label={params.row.connectors[0].currentStatus} /> : ''
    )
  },
  {
    field: ChargerSortFieldEnum.BUSINESS_ID,
    hide: RoleEnum.SUPER_ADMIN !== role,
    renderHeader: (params) => <FormattedMessage id={"businessColumnHeader"} />,
    flex: 2,
    minWidth: 100,
    cellClassName: 'ampTableCell',
    valueGetter: (params) => params.row.business?.id,
    renderCell: (params) => params.row.business?.name
  },
  {
    field: ChargerSortFieldEnum.LOCATION_ID,
    renderHeader: (params) => <FormattedMessage id={"locationNameColumnHeader"} />,
    flex: 4,
    minWidth: 160,
    cellClassName: 'ampTableCell',
    renderCell: (params) => <PopoverTypography popoverText={params.row.location?.name}
      text={params.row.location?.name}/>
  },
  
  {
    field: "client",
    renderHeader: (params) => <FormattedMessage id={"clientColumnHeader"} />,
    flex: 3,
    minWidth: 150,
    cellClassName: 'ampTableCell',
    sortable: false,
    valueGetter: (params) => params.row.user ? `${params.row.user.firstName} ${params.row.user.lastName}` : ''
  },
  
  {
    field: ChargerSortFieldEnum.CREATED_AT,
    renderHeader: (params) => <FormattedMessage id={"createdOnColumnHeader"} />,
    flex: 3,
    minWidth: 150,
    cellClassName: 'ampTableCell',
    sortable: true,
    valueGetter: (params) => params.row.createdAt ? dayjs(params.row.createdAt).format("DD/MM/YY") : '-'
  },
  ...(canRemove ?
    [
      {
        field: 'delete',
        headerName: '',
        flex: 1,
        sortable: false,
        cellClassName: 'ampTableCell hoverableCell',
        renderCell: (params: GridValueGetterParams) => {
          return (
            <DeleteCharger row={params.row} />
          )
        }
      }
    ] : []
  )
])
