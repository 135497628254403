import { Add, Edit } from "@mui/icons-material";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { locationsApi } from "src/api";
import { useAppDispatch, useAppSelector, useDefineIsRealEstateRole } from "src/app/hooks";
import { AppDispatch } from "src/app/store";
import AMPModal from "src/components/common/AMPModal";
import { DropZoneModal } from "src/components/common/DropZoneModal";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { NotifyError, NotifyWarning } from "src/utils/notification";
import { LocationUpdateFormWrapper } from "../LocationUpdateFormWrapper";
import { MapWrapper } from "src/components/map/MapWrapper";
import { blankPhoto, MapZoomEnum } from "src/utils/consts";
import { EditIcon } from "src/components/icons/EditIcon";
import { LocationDetailsType } from "./LocationDetails.types";
import {
  detailsWrapperSx,
  imageWrapperSx,
  buttonImageWrapperSx,
  imageButtonSx,
  detailsTitleSx,
  detailItemSx,
  imageInnerSx,
  editButtonSx,
  captionSx,
  costBoxSx,
  editCostBtnSx,
  editCostIconSx
} from "./LocationDetails.sx";
import { generateItemCost, isErrorObject } from "src/utils/helper";
import { EditLocationCostForm } from "../../forms/EditLocationCostForm";
import { CreateLocationCostForm } from "../../forms/CreateLocationCostForm";
import { ItemCost } from "../../../types";
import { Can } from "../../../permissions/Can";

type AddImageMutationTrigger = ReturnType<
  typeof locationsApi.useAddLocationImageMutation
>[0];

const handleTrigger = async (
  file: File[] | null,
  locationId: number,
  trigger: AddImageMutationTrigger,
  dispatch: AppDispatch,
  handleCloseImageModal: () => void,
  warningMessage: string,
  handleBlockSubmit: (status: boolean) => void,
  businessId?: number,
) => {
  if (!file) {
    NotifyWarning(warningMessage)
    return
  }
  try {
    handleBlockSubmit(true)
    dispatch(preloaderChangeStatus(true));
    const formData = new FormData()
    formData.append('file', file[0])
    formData.set('businessId', String(businessId))
    await trigger({ formData, locationId })
      .unwrap()
    handleCloseImageModal()
  } catch (error) {
    if (isErrorObject(error)) {
      NotifyError(error.data.message)
    }
  } finally {
    dispatch(preloaderChangeStatus(false));
    handleBlockSubmit(false)
  }
};

export const LocationDetails: FC<LocationDetailsType> = ({ data }) => {
  const intl = useIntl();

  const isRealEstate = useDefineIsRealEstateRole();

  const [open, setOpen] = useState<boolean>(false)
  const [openDropZone, setOpenDropZone] = useState<boolean>(false)
  const [locationImage, setLocationImage] = useState<File[] | null>(null)

  const warningMessage = intl.formatMessage({ id: 'uploadAFile' })
  const dialogTitle = intl.formatMessage({ id: 'editLocation' })
  const uploadDialogTitle = intl.formatMessage({ id: 'addNewImage' })
  const { authInfo } = useAppSelector((state) => state.common);
  const [blockSubmit, setBlockSubmit] = useState(false)
  const [showEditCost, setShowEditCost] = useState(false)
  const [showCreateCost, setShowCreateCost] = useState(false)

  const currentCurrency = data?.itemCosts?.[0]?.currency

  const chargersCosts = data.chargePoints.reduce((acc, cur) => ([...acc, ...cur.itemCosts]),
    [] as ItemCost[])
  
  const handleShowEditCostModal = (status: boolean) => () => {
    setShowEditCost(status)
  }

  const handleShowCreateCostModal = (status: boolean) => () => {
    setShowCreateCost(status)
  }

  const handleBlockSubmit = (status: boolean) => {
    setBlockSubmit(status)
  }

  const dispatch = useAppDispatch();
  const [trigger] = locationsApi.useAddLocationImageMutation();

  const handleOpenModal = () => {
    setOpen(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const handleCloseImageModal = () => {
    setOpenDropZone(false)
  }

  const handleSetImage = (file: File[] | null) => {
    setLocationImage(file)
  }

  const handleSubmit = () => {
    locationImage &&
      handleTrigger(locationImage, data.id, trigger, dispatch, handleCloseImageModal, warningMessage, handleBlockSubmit, data.business.id)
  }

  return (
    <>
      <Box sx={detailsWrapperSx}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 0, md: 2 }}
          p={1}
        >
          <Grid
            item
            xs={12}
            lg={2.5}
            sx={imageWrapperSx}
          >
            <Box sx={imageInnerSx}>
              <Box sx={buttonImageWrapperSx}>
                <IconButton
                  onClick={() => setOpenDropZone(true)}
                  sx={imageButtonSx}
                >
                  <Edit />
                </IconButton>
              </Box>
              <DropZoneModal
                submitIsBlocked={blockSubmit}
                handleClose={handleCloseImageModal}
                open={openDropZone}
                title={uploadDialogTitle}
                handleFile={handleSetImage}
                handleSubmit={handleSubmit}
                isImage
              />
              <img
                src={data.image?.url ?? blankPhoto}
                alt={data.name}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            p={1}
          >
            <Box sx={detailsTitleSx}>
              <Typography variant="subtitle1">
                <FormattedMessage id="locationDetails" />
              </Typography>
              {!isRealEstate && (
                <Can
                  I="get"
                  a="location"
                >
                  <IconButton
                    onClick={handleOpenModal}
                    sx={editButtonSx}
                  >
                    <EditIcon />
                  </IconButton>
                </Can>
              )}
            </Box>
            <Grid
              container
              columnSpacing={{ xs: 0, lg: 2 }}
            >
              <Grid
                item
                xs={12}
                lg={6}
                sx={detailItemSx}
              >
                <Stack>
                  <Typography
                    sx={captionSx}
                    variant="caption"
                  >
                    <FormattedMessage id="id" />
                  </Typography>
                  <Typography
                    variant="subtitle2"
                  >
                    {data?.id}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    sx={captionSx}
                    variant="caption"
                  >
                    <FormattedMessage id="name" />
                  </Typography>
                  <Typography
                    variant="subtitle2"
                  >
                    {data?.name}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    sx={captionSx}
                    variant="caption"
                  >
                    <FormattedMessage id="business" />
                  </Typography>
                  <Typography
                    variant="subtitle2"
                  >
                    {data?.business?.name}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    sx={captionSx}
                    variant="caption"
                  >
                    <FormattedMessage id="availableAmperes" />
                  </Typography>
                  <Typography
                    variant="subtitle2"
                  >
                    {data.availableAmperes}
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                sx={detailItemSx}
              >
                <Stack>
                  <Typography
                    sx={captionSx}
                    variant="caption"
                  >
                    <FormattedMessage id="currentCostLocation" />
                  </Typography>
                  <Box sx={costBoxSx}>
                    <Typography
                      variant="subtitle2"
                    >
                      {generateItemCost([...data.itemCosts, ...chargersCosts])}
                    </Typography>
                    {!isRealEstate && !!data?.itemCosts?.length &&
                      <IconButton
                        onClick={handleShowEditCostModal(true)}
                        sx={editCostBtnSx}
                      >
                        <Edit sx={editCostIconSx} />
                      </IconButton>
                    }
                    {!isRealEstate && (
                      <Can
                        I="manage"
                        a="costLocation"
                      >
                        <IconButton
                          onClick={handleShowCreateCostModal(true)}
                          sx={editCostBtnSx}
                        >
                          <Add sx={editCostIconSx} />
                        </IconButton>
                      </Can>
                    )}
                  </Box>
                </Stack>
                <Stack>
                  <Typography
                    sx={captionSx}
                    variant="caption"
                  >
                    <FormattedMessage id="parkingLots" />
                  </Typography>
                  <Typography
                    variant="subtitle2"
                  >
                    {data.parkingLots}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    sx={captionSx}
                    variant="caption"
                  >
                    <FormattedMessage id="address" />
                  </Typography>
                  <Typography
                    variant="subtitle2"
                  >
                    {`${data.address.country}, ${data.address.city}, ${data.address.street}, ${data.address.houseNumber}`}
                  </Typography>
                </Stack>
                {
                  data?.realEstate?.name && (
                    <Stack>
                      <Typography
                        sx={captionSx}
                        variant="caption"
                      >
                        <FormattedMessage id="reOwner" />
                      </Typography>
                      <Typography
                        variant="subtitle2"
                      >
                        {data.realEstate.name}
                      </Typography>
                    </Stack>
                  )
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            lg={3.5}
            height={300}
          >
            <MapWrapper
              markers={[{ lat: data.latitude, lng: data.longitude }]}
              mapCenter={{ lat: data.latitude, lng: data.longitude }}
              zoom={MapZoomEnum.HIGH} />
          </Grid>
        </Grid>
      </Box>
      <AMPModal
        handleClose={handleShowEditCostModal(false)}
        open={showEditCost}
        title={intl.formatMessage({id: "currentCostLocation"})}
        type="md"
        fullWidth
        withoutPaddings
      >
        <EditLocationCostForm
          onClose={handleShowEditCostModal(false)}
          data={data?.itemCosts ?? []}
          chargersCosts={chargersCosts}
          locationId={data.id}
          businessId={authInfo?.business?.id}
        />
      </AMPModal>
      <AMPModal
        handleClose={handleShowCreateCostModal(false)}
        open={showCreateCost}
        title={intl.formatMessage({ id: "currentCostLocation" })}
        type="md"
        fullWidth
        withoutPaddings
      >
        <CreateLocationCostForm
          currentCurrency={currentCurrency}
          onClose={handleShowCreateCostModal(false)}
          locationId={data.id}
          businessId={authInfo?.business?.id}
        />
      </AMPModal>
      <AMPModal
        handleClose={handleCloseModal}
        open={open}
        title={dialogTitle}
        type="md"
        fullWidth
        withoutPaddings
      >
        <LocationUpdateFormWrapper
          handleClose={handleCloseModal}
          authInfo={authInfo}
          initialValue={{...data, realEstateId: data?.realEstate?.id ?? null}}
          locationId={data.id}
        />
      </AMPModal>
    </>
  );
};
