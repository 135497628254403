import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "src/axiosBaseQuery";
import { DefaultResponse } from "src/types";
import {
  PageReOwnersDTO,
  ReOwnerCreationRequest,
  ReOwnerDTO,
  ReOwnerGetRealEstateRequest,
  ReOwnerGetReOwnersRequest,
} from "src/types/reOwner";
import { getBaseURL } from "src/utils/helper";

export const reOwnerApi = createApi({
  reducerPath: "reOwnerApi",
  baseQuery: axiosBaseQuery({
    baseUrl: String(getBaseURL() + "/realEstates"),
  }),
  endpoints: (build) => {
    return {
      getRealEstate: build.query<ReOwnerDTO, ReOwnerGetRealEstateRequest>({
        query: (data) => ({
          url: `/${data.realEstateId}?businessId=${data.businessId}`,
          method: "get",
        }),
      }),
      createNewReOwner: build.mutation<DefaultResponse, ReOwnerCreationRequest>(
        {
          query: (data) => ({ url: "", method: "post", data }),
        }
      ),
      getReOwners: build.query<PageReOwnersDTO, ReOwnerGetReOwnersRequest>({
        query: ({ data, params }) => ({
          url: "/search",
          method: "post",
          data: {
            businessId: data.businessId,
          },
          params,
        }),
      }),
    };
  },
});
