import { Box, Tab, Tabs } from "@mui/material";
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { usersApi } from "src/api";
import { useAppSelector, useDefineIsRealEstateRole } from "src/app/hooks";
import { store } from "src/app/store";
import { tabSx } from "src/components/businesses/BusinessTables/BusinessTables.sx";
import AMPModal from "src/components/common/AMPModal";
import { PageHeader } from "src/components/common/PageHeader";
import { TabPanel } from "src/components/common/TabPanel";
import { BusinessForm } from "src/components/forms/BusinessForm";
import { UserForm } from "src/components/forms/UserForm";
import { UsersTable } from "src/components/users/UsersTable";
import { ContractStatusEnum, RoleEnum } from "src/enums";
import { UsersSortFieldEnum } from "src/enums/users";
import ability from "src/permissions/ability";
import { preloaderChangeStatus } from "src/stores/common/commonSlice";
import { DataType, ListParamsType, ReactLocationStateType } from "src/types";
import { UsersSearchRequestData } from "src/types/users";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
type UsersQueryTrigger = ReturnType<typeof usersApi.useLazyGetUsersQuery>[0];

const handleGetUsers = (
  trigger: UsersQueryTrigger,
  businessId?: number,
  currentRole?: RoleEnum,
) => (async (tableData: UsersSearchRequestData, params: {page: number, size: number}) => {
  store.dispatch(preloaderChangeStatus(true))
  
  const adminRoles = [RoleEnum.DRIVER, RoleEnum.BUSINESS, RoleEnum.SUPER_ADMIN]
  const businessRoles = [RoleEnum.DRIVER, RoleEnum.BUSINESS]
  const roles = currentRole === RoleEnum.SUPER_ADMIN ? adminRoles : businessRoles
  const authInfo = store?.getState()?.common?.authInfo
  const role = authInfo?.roles?.[0].role
  const finalRolesArray = role === RoleEnum.REAL_ESTATE ? [RoleEnum.DRIVER] : tableData.roles ? tableData.roles : roles
  
  const newData: DataType = {
    ...tableData,
    businessId: tableData.businessId ? tableData.businessId : businessId,
    roles: finalRolesArray
  }

  Object.keys(tableData).map((item: string) => {
    !newData[item] && delete newData[item]
  })

  const data: UsersSearchRequestData = {
    ...newData,
  }

  try {
    const response = await trigger({ data, params }).unwrap()
    return response
  } catch (error) {
    if(isErrorObject(error)){
      NotifyError(error.data.message)
    }
  } finally {
    store.dispatch(preloaderChangeStatus(false));
  }
});

export const Users: FC = () => {
  const intl = useIntl();
  const [open, setOpen] = useState<boolean>(false)
  const dialogTitle = intl.formatMessage({ id: "createNewUser" })
  const { authInfo } = useAppSelector(state => state.common);
  const [tab, setTab] = useState<number>(0);
  
  const isRealEstate = useDefineIsRealEstateRole();

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate()

  const [trigger, response] = usersApi.useLazyGetUsersQuery()
  const [tableParams, setTableParams] = useState<ListParamsType>({
    page: 0,
    size: 100
  })
  
  const [filterParams, setFilterParams] = useState<UsersSearchRequestData>({
    keyword: '',
    businessId: 0,
    sortAsc: false,
    sortField: UsersSortFieldEnum.CREATED
  })
  const handleOpenModal = () => {
    setOpen(true)
  }
  const handleCloseModal = () => {
    setOpen(false)
    callHandler(filterParams, tableParams)
  }

  const handleChange = (value: UsersSearchRequestData) => {
    setFilterParams(value)
    setTableParams({
      page: 0,
      size: tableParams.size
    })
    setSearchParams({})
    callHandler(value, {
      page: 0,
      size: tableParams.size
    })
  }

  const handleChangePage = (page: number) => {
    callHandler(filterParams, {
      page,
      size: tableParams.size
    })
    setTableParams({
      page,
      size: tableParams.size
    })
    setSearchParams({
      page: String(page)
    })
  }

  const handleChangeRowsNumber = (e: ChangeEvent<HTMLInputElement>) => {
    callHandler(filterParams, {
      page: 0,
      size: +e.target.value || 100
    })
    setTableParams({
      page: 0,
      size: +e.target.value || 100
    })
  }

  const callHandler = handleGetUsers(trigger, authInfo.business && +authInfo.business.id, authInfo.roles[0].role)

  const readUsers = ability.can('read', 'users')
  const manageUsers = ability.can('manage', 'user')
  
  useEffect(() => {
    !manageUsers && setTab(1)
  }, [manageUsers])

  useEffect(() => {
    !readUsers && navigate(`/app/users/${authInfo.id}`);
    const currentState = location.state as ReactLocationStateType | undefined;
    const currentSearch = location.search
    if(currentState){
      setSearchParams({
        page: currentState?.page ?? currentSearch?.replace(/(\D+)(\d+)$/, "$2") ?? String(tableParams.page)
      })
      setTableParams({
        ...tableParams,
        page: +currentState?.page ?? +currentSearch?.replace(/(\D+)(\d+)$/, "$2") ?? tableParams.page
      })
      callHandler(filterParams, {
        ...tableParams,
        page: +currentState.page
      })
      return
    }
    callHandler(filterParams, tableParams)
  }, []);

  return (
    <Box>
      <PageHeader
        title={intl.formatMessage({ id: 'users' })}
        onClick={handleOpenModal}
        buttonText="addUser"
        onlyTitle={isRealEstate}
      />
      {response.data && (
        <UsersTable
          size={tableParams.size}
          data={response.data}
          pageNumber={tableParams.page}
          handleUpdateTableData={handleChange}
          handleChangePage={handleChangePage}
          handleChangeRowsNumber={handleChangeRowsNumber}
        />
      )}
      <AMPModal
        handleClose={handleCloseModal}
        open={open}
        title={dialogTitle}
        type="md"
        fullWidth
        withoutPaddings
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tab}
            onChange={(event: React.SyntheticEvent, newValue: number) =>
              setTab(newValue)
            }
            aria-label="basic tabs example"
          >
            {manageUsers && <Tab
              sx={tabSx}
              label={intl.formatMessage({id: 'user'})}
              value={0}
            />}
            {authInfo.roles[0].role === RoleEnum.SUPER_ADMIN && <Tab
              sx={tabSx}
              label={intl.formatMessage({id: 'business'})}
              value={1}
            />}
          </Tabs>
        </Box>
        <TabPanel
          value={tab}
          index={0}
        >
          <UserForm
            handleClose={handleCloseModal}
            businessId={authInfo.business ? authInfo.business.id : 1} // TODO: add default business id for adding user to AMPIT by super admin
          />
        </TabPanel>
        <TabPanel
          value={tab}
          index={1}
        >
          <BusinessForm
            handleClose={handleCloseModal}
          />
        </TabPanel>
      </AMPModal>
    </Box>
  )
}
