import { FC } from "react";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import { ReOwnerFormProps, reOwnersFormSchema } from "../formData/reOwners";
import { Box, Grid } from "@mui/material";
import { useIntl } from "react-intl";
import { AMPInput } from "src/components/common/AMPInput";
import { getHelperText } from "src/utils/forms";
import { reOwnersInitialValue } from "../formData/common/initialData";
import { CancelButton } from "src/components/common/CancelButton";
import { SubmitButton } from "src/components/common/SubmitButton";
import { ReOwnersFormProps } from "./ReOwnersForm.types";
import { actionsWrapperSx, formSx } from "./ReOwnersForm.sx";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { AppDispatch } from "src/types";
import { reOwnerApi } from "src/api";
import { isErrorObject } from "src/utils/helper";
import { NotifyError } from "src/utils/notification";
import { BusinessesDropdown } from "./BusinessesDropdown";
import { RoleEnum } from "src/enums";

type CreateNewReOwnerMutationTrigger = ReturnType<
  typeof reOwnerApi.useCreateNewReOwnerMutation
>[0];

export const ReOwnersForm: FC<ReOwnersFormProps> = ({ closeDialog }) => {
  const intl = useIntl();

  const { authInfo } = useAppSelector((state) => state.common);
  const businessId = authInfo?.business?.id;
  const isBusinessOwner = authInfo?.roles[0].role === RoleEnum.BUSINESS;

  const dispatch = useAppDispatch();

  const [trigger] = reOwnerApi.useCreateNewReOwnerMutation();

  const handleSubmit = (
    trigger: CreateNewReOwnerMutationTrigger,
    _dispatch: AppDispatch,
    handleClose: () => void
  ): FormikConfig<ReOwnerFormProps>["onSubmit"] => {
    return async ({ businessId, name }) => {
      try {
        await trigger({
          businessId,
          name,
        }).unwrap();
      } catch (error) {
        if (isErrorObject(error)) {
          NotifyError(error.data.message);
        }
      } finally {
        handleClose();
      }
    };
  };

  return (
    <Formik
      initialValues={{
        ...reOwnersInitialValue,
        businessId,
      }}
      validationSchema={reOwnersFormSchema}
      onSubmit={handleSubmit(trigger, dispatch, closeDialog)}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }: FormikProps<ReOwnerFormProps>) => (
        <Form
          onSubmit={handleSubmit}
          noValidate
        >
          <Box sx={formSx}>
            <Box>
              <Grid
                container
                spacing={2}
              >
                {!isBusinessOwner && (
                  <Grid
                    xs={12}
                    item
                  >
                    <BusinessesDropdown />
                  </Grid>
                )}

                <Grid
                  xs={12}
                  item
                >
                  <AMPInput
                    value={values.name}
                    type={"name"}
                    label={"name"}
                    input={"name"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={touched.name && Boolean(errors.name)}
                    helperText={getHelperText("name", touched, errors, intl)}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box sx={actionsWrapperSx}>
            <CancelButton
              text="cancel"
              handler={() => closeDialog()}
            />
            <SubmitButton
              text={"createReOwner"}
              color="primary"
              isSubmitting={isSubmitting}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};
