import { Box, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import AMPModal from "src/components/common/AMPModal";
import { PageHeader } from "src/components/common/PageHeader";
import { ReOwnersForm } from "src/components/forms/ReOwnersForm";
import { useNavigate } from "react-router-dom";
import ability from "src/permissions/ability";
import { ReOwnersTable } from "src/components/common/ReOwner/ReOwnersTable";

export const ReOwner: FC = () => {
  const intl = useIntl();

  const navigate = useNavigate();

  const permissions = ability.can("read", "reOwner");

  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);

  const reOwnerDialogTitle = intl.formatMessage({ id: "createNewReOwner" });

  useEffect(() => {
    if (!permissions) {
      return navigate("/404");
    }
  }, []);

  return (
    <>
      <Box>
        <Typography variant="h2">
          <PageHeader
            title={intl.formatMessage({ id: "reOwner" })}
            buttonText="addReOwner"
            onClick={() => setIsDialogVisible(true)}
          />
        </Typography>

        <AMPModal
          handleClose={() => setIsDialogVisible(false)}
          open={isDialogVisible}
          title={reOwnerDialogTitle}
          type="md"
          fullWidth
          withoutPaddings
        >
          <ReOwnersForm closeDialog={() => setIsDialogVisible(false)} />
        </AMPModal>
      </Box>

      <ReOwnersTable isDialogVisible={isDialogVisible} />
    </>
  );
};
