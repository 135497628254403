import { FormKeyValue, LatLngType, NotificationDTO, NotificationTypeEnum } from "src/types";
import { formatTime } from "./helper";
import { RoleEnum } from "../enums";
import { IntlShape } from "react-intl";

/* eslint-disable no-unused-vars */
export enum Times {
  ONE_S_IN_MS = 1000,
  THREE_S_IN_MS = 3000,
  FIVE_S_IN_MS = 5000, 
  ONE_YEAR = 1000 * 3600 * 24 * 365,
  ONE_MONTH = 1000 * 3600 * 24 * 30,
  ONE_WEEK = 1000 * 3600 * 24 * 7
}

export const allRegionsValue = '-1'

export const TWO_YEARS = 730

export const DEFAULT_EDITOR_HEIGHT = 400;

export enum Language {
  ENGLISH = "ENGLISH",
  FRENCH = "FRENCH"
}

export const LangConst: FormKeyValue = {
  en: Language['ENGLISH'],
  fr: Language["FRENCH"]
}

export enum ProceedCurrenciesEnum {
  EUR = 'EUR',
  CHF = 'CHF'
}

export enum MapZoomEnum {
  MIN = 4,
  NORMAL = 7,
  HIGH = 12,
  MAX = 16
}

export const phonePlaceholder = "+__ __ ___ __ __"

export const phoneAvailableCountries = ['fr', 'ch']

export const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const defaultMapCenter: LatLngType = {
  lat: 51.208214653794045,
  lng: 12.134815331213614
};

export const hoursArray = () => {
  const data = []
  for(let i = 0; i <= 23; i++){
    data.push(formatTime(i))
  }
  return data
}

export const minutesArray = () => {
  const data = []
  for(let i = 0; i <= 59; i++){
    data.push(formatTime(i))
  }
  return data
}

export const compileScheduleDate = (day: string, time: (string | number)[]) => {
  const fixedTime = time.join(':')
  return `${day}, ${fixedTime}`
}

export const page404 = '/static/images/404.svg'

export const blankPhoto = '/static/images/blank-photo.jpg'
export const blankUserPhoto = '/static/images/blank-user-photo.jpg'
export const longestChartNaming = 12

export const supportLinks = {
  helpPhoneNumber: "076 454 65 06",
  helpEmail: 'support@amp-it.com',
  companyPhoneNumber: '022 525 77 22',
  companyEmail: 'info@amp-it.com'
}

export const moreThenNinetyNine = '99+'

export const validFileNameRegEx = /^[a-zA-Z0-9](?:[a-zA-Z0-9 ._-]*[a-zA-Z0-9])?\.[a-zA-Z0-9]+$/gi

export const appleStoreLink = "https://apps.apple.com/us/app/amp-it-hub/id1643478107"
export const googlePlayLink = "https://play.google.com/store/apps/details?id=com.ampit.app&pli=1"

export const emptyConsumptionPlaceholder = "/static/images/empty_consumption.png"

export const notificationTypeTranslation= (intl: IntlShape):Record<NotificationTypeEnum, string> => (
  {
    [NotificationTypeEnum.CHANGE_PLAN_REQUEST]: intl.formatMessage({id: "changePlanRequest"}),
    [NotificationTypeEnum.NEW_CONTRACT_NEW_LOCATION]: intl.formatMessage({id: "newContractNewLocation"}),
    [NotificationTypeEnum.NEW_CONTRACT_EXIST_LOCATION]:  intl.formatMessage({id: "newContractExistingLocation"}),
    [NotificationTypeEnum.USER_FEEDBACK]:  intl.formatMessage({id: "userFeedback"}),
    [NotificationTypeEnum.NEW_USER_REGISTRATION]: intl.formatMessage({id: "newUserRegistrationExist"}),
    [NotificationTypeEnum.STATION_ASSOCIATION]: intl.formatMessage({id: "stationAssociation"}),
    [NotificationTypeEnum.NEW_RFID_REQUEST]: intl.formatMessage({id: "newRfidRequest"}),
    [NotificationTypeEnum.NEW_LOCATION]: intl.formatMessage({id: "newLocation"})
  }
);
export const notificationTypeTranslationMessage= (intl: IntlShape, notification: NotificationDTO):Record<NotificationTypeEnum, string> => (
  {
    [NotificationTypeEnum.CHANGE_PLAN_REQUEST]: notification?.message,
    [NotificationTypeEnum.NEW_CONTRACT_NEW_LOCATION]: intl.formatMessage({id: "newContractNewLocationMessage"}, { contractId: notification?.id}),
    [NotificationTypeEnum.NEW_CONTRACT_EXIST_LOCATION]:  intl.formatMessage({id: "newContractExistingLocationMessage"}, { email: notification?.user?.emailAddress}),
    [NotificationTypeEnum.USER_FEEDBACK]:  notification?.message,
    [NotificationTypeEnum.NEW_USER_REGISTRATION]: notification?.message,
    [NotificationTypeEnum.STATION_ASSOCIATION]: intl.formatMessage({id: "stationAssociationMessage"}, { contractId: notification?.id}),
    [NotificationTypeEnum.NEW_RFID_REQUEST]: notification?.message,
    [NotificationTypeEnum.NEW_LOCATION]: intl.formatMessage({id: "newLocationCreated"}, { name: notification?.location?.name })
  }
);

export const roleStatusRequest: Record<RoleEnum, NotificationTypeEnum[]> = {
  [RoleEnum.CLIENT_MANAGER]: [
    NotificationTypeEnum.CHANGE_PLAN_REQUEST,
    NotificationTypeEnum.USER_FEEDBACK,
    NotificationTypeEnum.NEW_USER_REGISTRATION,
    NotificationTypeEnum.STATION_ASSOCIATION,
    NotificationTypeEnum.NEW_RFID_REQUEST
  ],
  [RoleEnum.SALES_MANAGER]: [
    NotificationTypeEnum.NEW_CONTRACT_NEW_LOCATION,
    NotificationTypeEnum.NEW_LOCATION
  ],
  [RoleEnum.TECHNICAL_MANAGER]: [
    NotificationTypeEnum.NEW_CONTRACT_EXIST_LOCATION,
    NotificationTypeEnum.NEW_LOCATION
  ],
  [RoleEnum.BUSINESS]: [
    NotificationTypeEnum.CHANGE_PLAN_REQUEST,
    NotificationTypeEnum.NEW_CONTRACT_NEW_LOCATION,
    NotificationTypeEnum.NEW_CONTRACT_EXIST_LOCATION,
    NotificationTypeEnum.USER_FEEDBACK,
    NotificationTypeEnum.NEW_USER_REGISTRATION,
    NotificationTypeEnum.STATION_ASSOCIATION,
    NotificationTypeEnum.NEW_RFID_REQUEST,
    NotificationTypeEnum.NEW_LOCATION
  ],
  [RoleEnum.DRIVER]: [],
  [RoleEnum.SUPER_ADMIN]: [],
  [RoleEnum.REAL_ESTATE]: [],
}

export const managerRoles = [RoleEnum.TECHNICAL_MANAGER, RoleEnum.SALES_MANAGER, RoleEnum.CLIENT_MANAGER]