import { Box, Tab, Tabs, TabsProps } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useAppSelector, useDefineIsRealEstateRole } from "src/app/hooks";
import { ChargerListCard } from "src/components/chargers/ChargerListCard";
import { columnConfigChargers } from "src/components/chargers/ChargerTable/ChargerTable.tableConfig";
import { TabPanel } from "src/components/common/TabPanel";
import { ContractItemCard } from "src/components/contracts/ContractItemCard";
import { columnConfigContracts } from "src/components/contracts/ContractsTable/ContractsTable.tableConfig";
import { LocationItemCard } from "src/components/locations/LocationTable/LocationItemCard";
import { columnConfigLocations } from "src/components/locations/LocationTable/LocationTable.tableConfig";
import { Table } from "src/components/tables/Table";
import { TablePagination } from "src/components/tables/TablePagination";
import { TableRowCounter } from "src/components/tables/TableRowCounter";
import { rootSx, tableFooterSx, tabSx } from "./BusinessTables.sx";
import { BusinessTablesType } from "./BusinessTables.types";
import { ResponsiveTable } from "../../common/ResponsiveTable";

export const BusinessTables: FC<BusinessTablesType> = ({
  locations,
  chargers,
  contracts,
  handleChangePage,
  handleChangeRowsNumber,
  handleSetActiveTab,
  tab,
  page,
  size,
  locationsTotalPage,
  chargersTotalPage,
  contractsTotalPage,
  handleSort
}) => {

  const { authInfo } = useAppSelector(state => state.common)
  const handleSetTab: TabsProps['onChange'] = (event, newValue) => {
    handleSetActiveTab(newValue)
  }

  const isRealEstate = useDefineIsRealEstateRole();
  
  const intl = useIntl()
  return (
    <Box sx={rootSx}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tab}
          onChange={handleSetTab}
          aria-label="basic tabs example"
        >
          <Tab
            sx={tabSx}
            label={intl.formatMessage({id: 'locations'})}
          />
          <Tab
            sx={tabSx}
            label={intl.formatMessage({id: 'chargers'})}
          />
          <Tab
            sx={tabSx}
            label={intl.formatMessage({id: 'contracts'})}
          />
        </Tabs>
      </Box>

      {/* LOCATIONS TAB */}
      <TabPanel
        value={tab}
        index={0}
      >
        <ResponsiveTable
          responsiveElement={
            <Box>
              {locations.map(item => <LocationItemCard
                key={item?.id}
                {...item}
                canCreateDeleteLocation={!isRealEstate}
              />)}
            </Box>
          }
          desktopElement={
            <Table
              handleSort={handleSort}
              rows={locations}
              hideFooter
              columns={columnConfigLocations(!isRealEstate)}
            />
          }
        />
        <Box sx={tableFooterSx}>
          <TableRowCounter
            defaultValue={size}
            handleChangeRowsNumber={handleChangeRowsNumber}
          />
          <TablePagination
            totalPages={locationsTotalPage}
            page={page}
            handleChangePage={handleChangePage}
          />
        </Box>
      </TabPanel>

      {/* CHARGERS TAB */}
      <TabPanel
        value={tab}
        index={1}
      >
        <ResponsiveTable
          responsiveElement={
            <Box>
              {chargers.map(item => (
                <ChargerListCard
                  key={item?.id}
                  {...item}
                  canRemoveCharger={!isRealEstate}
                />
              ))}
            </Box>
          }
          desktopElement={
            <Table
              handleSort={handleSort}
              rows={chargers}
              hideFooter
              columns={columnConfigChargers(authInfo.roles[0].role, !isRealEstate)}
            />
          }
        />
        <Box sx={tableFooterSx}>
          <TableRowCounter
            defaultValue={size}
            handleChangeRowsNumber={handleChangeRowsNumber}
          />
          <TablePagination
            totalPages={chargersTotalPage}
            page={page}
            handleChangePage={handleChangePage}
          />
        </Box>
      </TabPanel>

      {/* CONTRACTS TAB */}
      <TabPanel
        value={tab}
        index={2}
      >
        <ResponsiveTable
          responsiveElement={
            <Box>
              {contracts.map(item => <ContractItemCard
                key={item?.id}
                {...item}
              />)}
            </Box>
          }
          desktopElement={
            <Table
              handleSort={handleSort}
              rows={contracts}
              hideFooter
              columns={columnConfigContracts(authInfo.roles[0].role)}
            />
          }
        />
        <Box sx={tableFooterSx}>
          <TableRowCounter
            defaultValue={size}
            handleChangeRowsNumber={handleChangeRowsNumber}
          />
          <TablePagination
            totalPages={contractsTotalPage}
            page={page}
            handleChangePage={handleChangePage}
          />
        </Box>
      </TabPanel>
    </Box>
  );
};
