import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import axios, { AxiosRequestConfig, AxiosError, Method } from 'axios'
import CryptoJs from 'crypto-js'
import { authApi } from 'src/api';
import { store } from 'src/app/store';
import { Times } from 'src/utils/consts';
import { getBaseURL } from 'src/utils/helper';
import { NotifyError } from 'src/utils/notification';
import { authHeadersFromLocalStorage, cryptoKey, rejectError } from './interceprtors';

const configuration: AxiosRequestConfig = {
  url: '/',
  method: 'get' as Method,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 45000,
  responseType: 'json',
  maxContentLength: 2000,
  validateStatus: (status: number) => status >= 200 && status < 300,
  maxRedirects: 5,
};

export const axiosInstance = axios.create(configuration)

axiosInstance.interceptors.request.use(authHeadersFromLocalStorage, rejectError);

axiosInstance.interceptors.response.use(
  async (response) => {

    const { headers, config } = response;
    const isNewAuthRequest = headers.authorization && headers['refresh-token']
    
    if (!isNewAuthRequest) {
      return response
    }

    const tokenData = JSON.parse(decodeURIComponent(atob(headers.authorization.split('.')[1])))
    const tokenIsExpired = tokenData.exp * Times.ONE_S_IN_MS <= (new Date()).getTime();
    
    if(tokenIsExpired) {
      console.log('local storage will be clear');
      
      localStorage.clear()
      return response
    }

    const authData = {
      authorization: headers.authorization,
      refreshToken: headers['refresh-token'],
      expireDate: tokenData.exp
    }
    const crypto = CryptoJs.AES.encrypt(JSON.stringify(authData), cryptoKey.toString()).toString()
    localStorage.setItem('auth', crypto);
    return response
  },
  undefined
);

axiosInstance.interceptors.response.use(
  undefined,
  async (error) => {
    const { response, config, message } = error;
    // NotifyError(response?.data?.message ?? message);
    if(config.url === `${getBaseURL()}/auth/refresh/token` && response.status === 406){
      localStorage.clear();
      return Promise.reject(error);
    }
    if(response.status !== 401 || response.data.messages !== "Expired or invalid JWT token") {
      return Promise.reject(error);
    }
    const auth = localStorage.getItem('auth') ?? ''
    const localData = JSON.parse(CryptoJs.AES.decrypt(auth, cryptoKey.toString()).toString(CryptoJs.enc.Utf8))
    try {
      await store.dispatch(authApi.endpoints.refreshToken.initiate({ token: localData.refreshToken }, { forceRefetch: true }));
      return axiosInstance.request(config)
    } catch (error) {
      console.error(error)
    }
    return Promise.reject(error);
  },
)

type AxiosBaseQueryRequestConfig = Omit<AxiosRequestConfig, 'url' | 'method'> & { url: string, method: NonNullable<AxiosRequestConfig['method']> }
export type AxiosBaseQueryFn = BaseQueryFn<AxiosBaseQueryRequestConfig, unknown, unknown>

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): AxiosBaseQueryFn =>
    async ({ url, ...rest }) => {
      try {
        const result = await axiosInstance({ url: baseUrl + url, ...rest })
        return { data: result.data }
      } catch (axiosError) {
        const err = axiosError as AxiosError
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        }
      }
    }
