import { SxProps, Theme } from "@mui/material";

export const addReManagerButtonSx = (_theme: Theme): SxProps<Theme> => ({
  py: 1.5,
  mr: 2,
});

export const formSx = (theme: Theme): SxProps<Theme> => ({
  display: "grid",
  gridTemplateColumns: "auto",
  gap: theme.spacing(20 / 8),
  padding: theme.spacing(20 / 8),
});

export const actionsWrapperSx = (theme: Theme): SxProps<Theme> => ({
  padding: theme.spacing(20 / 8, 24 / 8),
  borderTop: `${theme.spacing(1 / 8)} solid ${theme.palette.neutral[200]}`,
  background: theme.palette.neutral[50],
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: theme.spacing(20 / 8),
});
