import { Ability, AbilityBuilder } from '@casl/ability';
import { store } from 'src/app/store';
import { RoleEnum } from 'src/enums';
import { AuthUserDTO } from 'src/types';
type Actions = 'read' | 'manage' | 'create' | 'signing' | 'delete' | 'add' | 'get';
type Subjects = 'all'
  | 'lists'
  | 'contract'
  | 'userPhoto'
  | 'singlePages'
  | 'changePlan'
  | 'editUser'
  | 'editName'
  | 'files'
  | 'activeCharging'
  | 'resetChargers'
  | 'businessAccount'
  | 'businessReports'
  | 'reports'
  | 'chargers'
  | 'locations'
  | 'users'
  | 'invoices'
  | 'tariffs'
  | 'contracts'
  | 'technicalTeams'
  | 'notifications'
  | 'charger'
  | 'tariff'
  | 'location'
  | 'report'
  | 'user'
  | 'invoice'
  | 'schedule'
  | 'costLocation'
  | 'costCharger'
  | 'resetPassword'
  | 'rfId'
  | 'termsOfContract'
  | 'costData'
  | 'technicalTeam'
  | 'bexioId'
  | 'archiveContract'
  | 'uploadContract'
  | 'deleteUserStation'
  | 'reOwner'
  
type AppAbility = Ability<[Actions, Subjects]>;

const ability = new Ability<[Actions, Subjects]>();

let currentAuth: AuthUserDTO | null;
store.subscribe(() => {
  const prevAuth = currentAuth;
  currentAuth = store.getState().common.authInfo;
  if (prevAuth !== currentAuth) {
    ability.update(defineRulesFor(currentAuth));
  }
});

// Ability by user role
function defineRulesFor(user: AuthUserDTO | null) {
  const { can, cannot, rules } = new AbilityBuilder<AppAbility>(Ability);
  if(!user?.roles){
    return rules
  }

  switch (user?.roles[0].role) {
    case RoleEnum.SUPER_ADMIN:
      can('manage', 'all'); // NOT DELETE - this is to display the correct menu in the navigation
      can('create', 'businessAccount');
      can('read', 'businessReports')
      can('read', "editName")
      can('read', 'reports')
      can('read', 'archiveContract')
      can('read', 'chargers')
      can('read', 'locations')
      can('read', 'users')
      can('read', 'invoices')
      can('read', 'tariffs')
      can('read', 'contracts')
      can('read', 'technicalTeams')
      can('read', 'rfId')
      can('read', 'costData')
      can('manage', 'user')
      can('manage', 'reOwner')
      cannot('manage', 'bexioId')
      cannot('read', 'editUser')
      cannot('read', 'editName')
      cannot('manage', 'technicalTeam')
      cannot('manage', 'businessAccount')
      cannot('manage', 'resetChargers')
      cannot('manage', 'contract');
      cannot('manage', 'costCharger')
      cannot('manage', 'costLocation')
      cannot('delete', 'deleteUserStation')
      cannot('manage', 'tariff')
      cannot('manage', 'location')
      cannot('read', 'notifications')
      cannot('delete', 'files');
      cannot('read', 'uploadContract')
      cannot('read', 'notifications');
      cannot('manage', 'contract');
      cannot('read', 'contract');
      cannot('manage', 'charger');
      cannot('manage', 'userPhoto');
      cannot('read', 'changePlan');
      cannot('manage', 'activeCharging');
      cannot('manage', 'schedule')
      break;
    case RoleEnum.BUSINESS:
      can('manage', 'all'); // NOT DELETE - this is to display the correct menu in the navigation
      can('manage', 'businessAccount')
      can('manage', 'chargers')
      can('manage', 'resetChargers')
      can('manage', 'contract');
      can('manage', 'reports')
      can('manage', 'locations')
      can('manage', 'users')
      can('manage', 'invoices')
      can('manage', 'costCharger')
      can('manage', 'costLocation')
      can('manage', 'tariffs')
      can('manage', 'contracts')
      can('manage', 'location')
      can('manage', 'technicalTeams')
      can('read', "editName")
      can('delete', 'deleteUserStation')
      can('read', 'reports')
      can('read', 'archiveContract')
      can('read', 'chargers')
      can('read', 'locations')
      can('read', 'users')
      can('read', 'invoices')
      can('read', 'tariffs')
      can('read', 'uploadContract')
      can('read', 'contracts')
      can('read', 'costData')
      can('read', 'contract')
      can('read', 'technicalTeams')
      can('read', 'notifications');
      can('read', 'resetPassword')
      can('read', 'rfId')
      can('manage', 'activeCharging')
      can('manage', 'bexioId')
      can('manage', 'reOwner')
      cannot('read', 'termsOfContract')
      cannot('create', 'all')
      cannot('read', 'businessReports')
      cannot('read', 'editUser')
      cannot('read', 'changePlan')
      cannot('manage', 'userPhoto')
      cannot('signing', 'contract')
      cannot('manage', 'schedule')
      break;
    case RoleEnum.DRIVER:
      can('read', 'all');
      can('read', 'changePlan')
      can('signing', 'contract')
      can('manage', 'userPhoto')
      can('read', 'editUser')
      can('manage', 'activeCharging')
      can('read', 'notifications');
      can('manage', 'schedule')
      can('read', 'rfId')
      can('read', 'termsOfContract')
      cannot('manage', 'bexioId')
      cannot('delete', 'deleteUserStation')
      cannot('read', 'costData')
      cannot('read', 'notifications');
      cannot('manage', 'resetChargers')
      cannot('read', "editName")
      cannot('manage', 'charger')
      cannot('read', 'singlePages')
      cannot('read', 'lists')
      cannot('read', "editName")
      cannot('read', 'uploadContract')
      cannot('read', 'archiveContract')
      cannot('read', 'reports')
      cannot('read', 'chargers')
      cannot('read', 'locations')
      cannot('read', 'users')
      cannot('read', 'tariffs')
      cannot('read', 'contracts')
      cannot('read', 'technicalTeams')
      break;
    case RoleEnum.CLIENT_MANAGER:
      can('manage', 'all'); // NOT DELETE - this is to display the correct menu in the navigation
      can('read', 'users');
      can('read', 'contracts');
      can('read', 'contract')
      can('manage', 'user');
      can('manage', 'contracts');
      can('read', 'invoices')
      can('read', 'notifications');
      can('read', 'tariffs')
      can('read', 'locations')
      can('read', 'chargers')
      can('add', 'users')
      can('get', 'locations')
      can('read', 'resetPassword')
      can('read', 'rfId')
      can('read', 'reports')
      can('read', 'costData')
      can('manage', 'bexioId')
      cannot('manage', 'costCharger')
      cannot('manage', 'costLocation')
      cannot('manage', 'userPhoto')
      cannot('manage', 'report')
      cannot('read', 'businessReports')
      cannot('read', 'technicalTeams')
      cannot('manage', 'tariff')
      cannot('manage', 'location')
      cannot('read', 'archiveContract')
      cannot('manage', 'charger')
      cannot('manage', 'resetChargers');
      cannot('delete', 'deleteUserStation')
      cannot('manage', 'schedule')
      cannot('get', 'location')
      break;
    case RoleEnum.TECHNICAL_MANAGER:
      can('manage', 'all'); // NOT DELETE - this is to display the correct menu in the navigation
      can('read', 'locations')
      can('manage', 'locations')
      can('read', 'chargers')
      can('manage', 'charger')
      can('read', 'notifications');
      can('manage', 'schedule')
      can('manage', 'costCharger')
      can('manage', 'costLocation')
      can('manage', 'resetChargers');
      can('read', 'reports')
      can('read', 'costData')
      cannot('manage', 'bexioId')
      cannot('read', 'rfId')
      cannot('read', 'invoices')
      cannot('read', 'contract')
      cannot('read', 'tariffs')
      cannot('delete', 'deleteUserStation')
      cannot('read', 'archiveContract')
      cannot('read', 'contracts');
      cannot('read', 'users');
      cannot('read', 'businessReports')
      cannot('read', 'resetPassword')
      cannot('manage', 'invoice')
      cannot('manage', 'tariff')
      cannot('manage', 'location')
      cannot('manage', 'contract');
      cannot('manage', 'user');
      cannot('manage', 'report')
      cannot('read', 'technicalTeams')
      break;
    case RoleEnum.SALES_MANAGER:
      can('manage', 'all'); // NOT DELETE - this is to display the correct menu in the navigation
      can('read', 'users');
      can('read', 'contracts');
      can('read', 'locations')
      can('manage', 'locations')
      can('read', 'tariffs')
      can('manage', 'tariffs')
      can('manage', 'location')
      can('read', 'notifications');
      can('manage', 'costLocation')
      can('read', 'reports')
      can('read', 'costData')
      cannot('manage', 'bexioId')
      cannot('read', 'rfId')
      cannot('read', 'resetPassword')
      cannot('manage', 'costCharger')
      cannot('read', 'invoices')
      cannot('read', 'businessReports')
      cannot('read', 'technicalTeams')
      cannot('read', 'chargers')
      cannot('manage', 'invoice')
      cannot('read', 'archiveContract')
      cannot('manage', 'report')
      cannot('delete', 'deleteUserStation')
      cannot('manage', 'charger')
      cannot('manage', 'resetChargers');
      cannot('manage', 'schedule')
      cannot('manage', 'user');
      break;
    case RoleEnum.REAL_ESTATE:
      can('manage', 'all'); // NOT DELETE - this is to display the correct menu in the navigation
      can('read', 'chargers')
      can('read', 'users')
      can('read', 'contracts')
      can('read', 'locations')
      can('read', 'reports')
      can('read', 'businessAccount')
      can('read', 'resetChargers')
      can('read', 'contract');
      can('read', 'reports')
      can('read', 'invoices')
      can('read', 'costCharger')
      can('read', 'costLocation')
      can('read', 'tariffs')
      can('read', 'location')
      can('read', "editName")
      can('delete', 'deleteUserStation')
      can('read', 'archiveContract')
      can('read', 'chargers')
      can('read', 'locations')
      can('read', 'users')
      can('read', 'uploadContract')
      can('read', 'contracts')
      can('read', 'costData')
      can('read', 'contract')
      can('read', 'technicalTeams')
      can('read', 'resetPassword')
      can('read', 'rfId')
      can('read', 'activeCharging')
      can('read', 'bexioId')
      can('read', 'invoices')
      cannot('read', 'reOwner')
      cannot('read', 'tariffs')
      cannot('read', 'notifications');
      cannot('read', 'technicalTeams')
      cannot('read', 'termsOfContract')
      cannot('create', 'all')
      cannot('read', 'businessReports')
      cannot('read', 'editUser')
      cannot('read', 'changePlan')
      cannot('read', 'userPhoto')
      cannot('signing', 'contract')
      cannot('read', 'schedule')
      break;
  }

  return rules;
}

export default ability;
