import { FC, MouseEvent, useState } from "react";
import { Button, Popover, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { chargersApi } from "../../../api";
import { useAppDispatch } from "../../../app/hooks";
import { NotifyError } from "../../../utils/notification";
import { AppRouteEnum, AppRouteSpace } from "../../../utils/helper";
import { UserIcon } from "../../icons/UserIcon";
import { TableRow } from "../../tables/respnsiveTable/TableRow";
import { MenuButton } from "../../tables/respnsiveTable/MenuButton";
import { MenuWrapper } from "../../tables/respnsiveTable/MenuWrapper";
import { TableCard } from "../../tables/respnsiveTable/TableCard";
import { ChargePointDTO } from "../../../types";
import { StatusField } from "../../common/StatusField";
import { ColoredStatus } from "../../common/ColoredStatus";
import { AppDispatch } from "../../../app/store";
import { preloaderChangeStatus } from "../../../stores/common/commonSlice";
import { chargerListCardSx } from "./ChargerListCard.sx";

type DeleteChargerMutationTrigger = ReturnType<typeof chargersApi.useDeleteChargePointMutation>[0]

const handleDeleteCharger = (trigger: DeleteChargerMutationTrigger, dispatch: AppDispatch, businessId: number, chargerId: number) => async () => {
  dispatch(preloaderChangeStatus(true))
  try{
    await trigger({ businessId, chargerId }).unwrap()
  } catch(error: any) {
    error?.data && NotifyError(error.data.message)
  } finally {
    dispatch(preloaderChangeStatus(false))
  }
}

export const ChargerListCard:FC<ChargePointDTO> = ({
  id,
  chargePointId,
  currentStatus,
  connectors,
  business,
  location,
  user,
  canRemoveCharger
}) => {
  const intl = useIntl();
  const [deleteMutation] = chargersApi.useDeleteChargePointMutation()
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <TableCard>
      <MenuWrapper>
        {canRemoveCharger && <MenuButton handleClick={handleClick}/>}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={chargerListCardSx.popover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Button
            sx={chargerListCardSx.menuBtn}
            onClick={handleDeleteCharger(deleteMutation, dispatch, +business?.id, id)}
          >
            <Typography sx={chargerListCardSx.menuBtnText}>
              {intl.formatMessage({id: "delete"})}
            </Typography>
          </Button>
        </Popover>
      </MenuWrapper>
      <TableRow
        titleId='chargerId'
        isColumn
        link={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.chargers, String(id)])}
        value={chargePointId}
      />
      <TableRow
        titleId='stationStatus'
        customValue={
          <StatusField
            label={currentStatus}
          />
        }
      />
      <TableRow
        titleId='connectorStatus'
        customValue={connectors?.length ? <ColoredStatus label={connectors[0].currentStatus} /> : ''}
      />
      <TableRow
        titleId='business'
        value={business?.name}
      />
      <TableRow
        titleId='locationNameColumnHeader'
        value={location?.name}
      />
      <TableRow
        titleId='client'
        value={user ? `${user?.firstName} ${user?.lastName}` : "-"}
        icon={<UserIcon />}
      />
    </TableCard>
  )
}
