import { Box, SvgIcon, Toolbar, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { NavLink, useLocation } from "react-router-dom";
import { AppRouteEnum, AppRouteSpace } from "src/utils/helper";
import {
  ChargersIcon,
  DashboardIcon,
  ContractsIcon,
  InvoicesIcon,
  LocationsIcon,
  ReportsIcon,
  TariffsIcon,
  UsersIcon,
  NotificationsIcon,
  TechnicalTeamIcon
} from "src/components/icons/ToolbarIcons";
import { toolbarSx, linkSx } from "./BusinessNavigation.sx";
import { RoleEnum } from "src/enums";
import { useAppSelector } from "src/app/hooks";
import { Can } from "src/permissions/Can";
import { NotificationMarker } from "../NotificationMarker";
import { MapsHomeWorkSharp } from '@mui/icons-material';

export const BusinessNavigation: FC = () => {
  const location = useLocation()
  const { authInfo } = useAppSelector(state => state.common)
  const isBusinessPage = useMemo(() => {
    if (!authInfo.business) {
      return false
    }
    return location.pathname === AppRouteSpace([AppRouteEnum.app, AppRouteEnum.users, AppRouteEnum.business, authInfo.business.id])
  }, [location])

  return (
    <Toolbar sx={toolbarSx}>
      {(authInfo.roles[0].role === RoleEnum.BUSINESS || authInfo.roles[0].role === RoleEnum.REAL_ESTATE) && <Box
        sx={linkSx()}
      >
        <NavLink
          to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.users, AppRouteEnum.business, authInfo.business.id])}
        >
          <SvgIcon
            component={DashboardIcon}
            viewBox="0 0 20 20"
          />
          <Typography variant="subtitle1">
            <FormattedMessage id="dashboard" />
          </Typography>

        </NavLink>
      </Box>}
      <Can
        I="read"
        a="locations"
      >
        <Box
          sx={linkSx()}
        >
          <NavLink
            to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.locations])}
          >
            <SvgIcon
              component={LocationsIcon}
              viewBox="0 0 20 20"
            />
            <Typography variant="subtitle1">
              <FormattedMessage id="locations" />
            </Typography>
          </NavLink>
        </Box>
      </Can>
      <Can
        I="read"
        a="chargers"
      >
        <Box
          sx={linkSx()}
        >

          <NavLink
            to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.chargers])}
          >
            <SvgIcon
              component={ChargersIcon}
              viewBox="0 0 20 20"
            />
            <Typography variant="subtitle1">
              <FormattedMessage id="chargers" />
            </Typography>
          </NavLink>
        </Box>
      </Can>
      <Can
        I="read"
        a="users"
      >
        <Box
          sx={linkSx(isBusinessPage)}
        >
          <NavLink
            to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.users])}
          >
            <SvgIcon
              component={UsersIcon}
              viewBox="0 0 20 20"
            />
            <Typography variant="subtitle1">
              <FormattedMessage id="users" />
            </Typography>
          </NavLink>
        </Box>
      </Can>
      <Can
        I="read"
        a="contracts"
      >
        <Box
          sx={linkSx()}
        >
          <NavLink
            to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.contracts])}
          >
            <SvgIcon
              component={ContractsIcon}
              viewBox="0 0 20 20"
            />
            <Typography variant="subtitle1">
              <FormattedMessage id="contracts" />
            </Typography>
          </NavLink>
        </Box>
      </Can>
      <Can
        I="read"
        a="tariffs"
      >
        <Box
          sx={linkSx()}
        >
          <NavLink
            to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.tariffs])}
          >
            <SvgIcon
              component={TariffsIcon}
              viewBox="0 0 20 20"
            />
            <Typography variant="subtitle1">
              <FormattedMessage id="tariffs" />
            </Typography>
          </NavLink>
        </Box>
      </Can>
      <Can
        I="read"
        a="reports"
      >
        <Box
          sx={linkSx()}
        >
          <NavLink
            to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.reports])}
          >
            <SvgIcon
              component={ReportsIcon}
              viewBox="0 0 20 20"
            />
            <Typography variant="subtitle1">
              <FormattedMessage id="reports" />
            </Typography>
          </NavLink>
        </Box>
      </Can>
      <Can
        I="read"
        a="invoices"
      >
        <Box
          sx={linkSx()}
        >
          <NavLink
            to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.invoices])}
          >
            <SvgIcon
              component={InvoicesIcon}
              viewBox="0 0 20 20"
            />
            <Typography variant="subtitle1">
              <FormattedMessage id="invoices" />
            </Typography>
          </NavLink>
        </Box>
      </Can>
      <Can
        I="read"
        a="technicalTeams"
      >
        <Box
          sx={linkSx()}
        >
          <NavLink
            to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.technicalTeam])}
          >
            <SvgIcon
              component={TechnicalTeamIcon}
              viewBox="0 0 20 20"
            />
            <Typography variant="subtitle1">
              <FormattedMessage id="technical-team" />
            </Typography>
          </NavLink>
        </Box>
      </Can>
      <Can
        I="read"
        a="notifications"
      >
        <Box
          sx={linkSx()}
        >
          <NavLink
            to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.notifications])}
          >
            <SvgIcon
              component={NotificationsIcon}
              viewBox="0 0 20 20"
            />
            <Typography variant="subtitle1">
              <FormattedMessage id="notifications" />
            </Typography>
            <NotificationMarker/>
          </NavLink>
        </Box>
      </Can>
      <Can
        I="read"
        a="reOwner"
      >
        <Box
          sx={linkSx()}
        >
          <NavLink
            to={AppRouteSpace([AppRouteEnum.app, AppRouteEnum.reOwner])}
          >
            <SvgIcon
              component={MapsHomeWorkSharp}
              viewBox="0 0 20 20"
            />
            <Typography variant="subtitle1">
              <FormattedMessage id="reOwner" />
            </Typography>
          </NavLink>
        </Box>
      </Can>
    </Toolbar>
  )
}
