import { Box } from "@mui/material";
import { GridSortItem, GridSortModel } from "@mui/x-data-grid";
import { FC, useEffect, useState } from "react";
import { Table } from "../../tables/Table";
import { columnConfigUsers } from "./UsersTable.tableConfig";
import { UsersTableActions } from "../UsersTableAction";
import { UsersTableProps } from "./UsersTable.types";
import { TableRowCounter } from "src/components/tables/TableRowCounter";
import { TablePagination } from "src/components/tables/TablePagination";
import { UsersSortFieldEnum } from "src/enums";
import { UsersFilterValue } from "../UsersTableAction/UsersTableActions.types";
import { UsersSearchRequestData } from "src/types";
import { tableFooterSx, wrapperSx } from "./UsersTable.sx";
import { UserItemCard } from "./UserItemCard";
import { ResponsiveTable } from "../../common/ResponsiveTable";
import { useDefineIsRealEstateRole } from "src/app/hooks";

export const UsersTable: FC<UsersTableProps> = ({ data, size, pageNumber, handleUpdateTableData, handleChangePage, handleChangeRowsNumber }) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortValue, setSortValue] = useState<GridSortItem>({ field: '', sort: 'asc' });
  const [filterValue, setFilterValue] = useState<UsersFilterValue>({ role: '', businessId: 0 })

  const isRealEstate = useDefineIsRealEstateRole();
  
  const handleSearch = ( value: string ) => {
    setSearchValue(value)
    handleUpdateTableData(updateData(value, sortValue, filterValue))
  }

  const handleFilter = ( value: UsersFilterValue ) => {
    setFilterValue(value)
    handleUpdateTableData(updateData(searchValue, sortValue, value))
  }

  const handleSort = ( value: GridSortModel ) => {
    setSortValue(value[0])
    handleUpdateTableData(updateData(searchValue, value[0], filterValue))
  }

  const updateData = (search: string, sort: GridSortItem, filter: UsersFilterValue) => {
    const data: UsersSearchRequestData = {
      keyword: search,
      businessId: filter.businessId,
      sortAsc: sort ? sort.sort === 'asc' : true,
      sortField: (sort && sort.field) || UsersSortFieldEnum.EMAIL
    }
    if(filter.role) {
      data["roles"] = [filter.role]
    }
    return data
  }

  return (
    <Box sx={wrapperSx}>
      <UsersTableActions
        handleSearch={handleSearch}
        handleFilter={handleFilter}
      />
      <ResponsiveTable
        responsiveElement={
          <>
            {data.content.map(item => <UserItemCard
              key={item?.id}
              {...item}
              canDeleteUser={!isRealEstate}
            />)}
          </>
        }
        desktopElement={
          <Table
            rows={data.content}
            hideFooter
            columns={columnConfigUsers(!isRealEstate)}
            handleSort={handleSort}
          />
        }
      />
      <Box sx={tableFooterSx}>
        <TableRowCounter
          defaultValue={size}
          handleChangeRowsNumber={handleChangeRowsNumber}
        />
        <TablePagination
          totalPages={data.totalPages}
          page={pageNumber && pageNumber === 0 ? pageNumber : data.number}
          handleChangePage={handleChangePage}
        />
      </Box>
    </Box>
  )
}
